class FRecallAPI {
    constructor(api, version) {
        this.api = api;
        this.version = version;
    }

    listFRecall(id) {
        if (typeof id === 'number') {
            return this.api.get(`${this.version}/f_recall/${id}/`);
        }
        return this.api.get(`${this.version}/f_recall/`)
    }

    createFRecall(payload) {
        return this.api.post(`${this.version}/f_recall/`, payload);
    }

    updateFRecall(payload) {
        return this.api.put(`${this.version}/f_recall/${payload.id}/`, payload);
    }

    deleteFRecall(id) {
        return this.api.delete(`${this.version}/f_recall/${id}/`);
    }

    listLotCodeFRecall(payload) {
        return this.api.get(`${this.version}/frecall_lot_codes/`)
    }

}

export default FRecallAPI;