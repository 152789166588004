import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default function RecallIngredientList(props) {

  function makeBatchRows(ingredientObj) {
    if (!ingredientObj?.id) {
      return (
        <TableRow key={1}>
          <TableCell> Not </TableCell>
          <TableCell> Used </TableCell>
          <TableCell> In </TableCell>
          <TableCell> Production </TableCell>
        </TableRow>
      )
    }
    return (
      <TableRow key={1}>
        <TableCell> {ingredientObj?.ingredient?.name} </TableCell>
        <TableCell> {ingredientObj?.external_lot_code} </TableCell>
        <TableCell> {ingredientObj.receiving_record.supplier_name} </TableCell>
        <TableCell> {ingredientObj.receiving_record.receiving_date} </TableCell>
      </TableRow>
    );

    // return batchSheets.map((batchSheet, i) => {
    //   return (
    //     <TableRow key={i}>
    //       <TableCell> {props.skus[batchSheet.sku] ? props.skus[batchSheet.sku].sku_id : 'No Matching Sku'} </TableCell>
    //       <TableCell> {props.skus[batchSheet.sku] ? props.skus[batchSheet.sku].name : 'No Matching Sku'} </TableCell>
    //       <TableCell> {batchSheet.batch_sheet_id} </TableCell>
    //       <TableCell> {batchSheet.actual_unit_type ? batchSheet.actual_unit_value + ' ' + props.units[batchSheet.actual_unit_type].abbreviation : 'Production not finished.'} </TableCell>
    //       <TableCell> {batchSheet.production_date} </TableCell>
    //     </TableRow>
    //   )
    // })
  }

  function makeProductionTables() {
    if (Object.keys(props.ingredients).length === 0) {
      return (
        <Grid item container key={1} justify='center' alignItems='center' xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No Affected Ingredients</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
      )
    }
    else {
      return Object.keys(props.ingredients).map((ingredientObjKey, i) => {
        let ingredientObj = props.ingredients[ingredientObjKey]

        return (<>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid spacing={0} item container key={i} justify='center' alignItems='flex-start' xs={6}>
            <Paper style={{ width: '100%' }}>
              <Table style={{ margin: '0px auto 8px' }}>
                <TableBody>
                  <TableRow>
                    <TableCell> Ingredient Name </TableCell>
                    <TableCell> {ingredientObj?.ingredient?.name} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell> Ingredient Lot Code </TableCell>
                    <TableCell> {ingredientObj?.external_lot_code} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell> Category </TableCell>
                    <TableCell> {ingredientObj?.ingredient?.category} </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid spacing={0} item container key={i} justify='center' alignItems='flex-start' xs={6}>
            <Paper style={{ width: '100%' }}>
              <Table style={{ margin: '0px auto 8px' }}>
                <TableBody>
                  <TableRow>
                    <TableCell> Supplier </TableCell>
                    <TableCell> {ingredientObj?.receiving_record?.supplier_name} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell> Total Receving Qty </TableCell>
                    <TableCell> {ingredientObj?.receiving_unit_value ? Number(ingredientObj.receiving_unit_value).toFixed(2) + ' ' + props.units[ingredientObj?.unit_type].abbreviation : 'No Recevied Qty.'} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell> Total Pending Qty </TableCell>
                    <TableCell> {ingredientObj?.unit_value ? Number(ingredientObj.unit_value).toFixed(2) + ' ' + props.units[ingredientObj?.unit_type].abbreviation : 'No Pending Qty.'} </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>

          <Grid spacing={0} item container key={i} justify='center' alignItems='center' xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Ingredient Name</TableCell>
                    <TableCell>Lot Code</TableCell>
                    <TableCell>Supplier Name</TableCell>
                    <TableCell>Receiving Date</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {makeBatchRows(ingredientObj)}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>)
      })
    }
  }

  // console.log("props.ingredients >> ", props.ingredients);
  return (
    <Grid container spacing={3}>
      <Grid item container xs={12} alignItems="left" justify="flex-start">
        <Typography variant='h6'>
          Ingredient List
        </Typography>
      </Grid>
      <Grid item container xs={12} alignItems="left" justify="flex-start">
        <Typography>
          {/* A list of all ingredients and the affected production activities that involve the recalled lot codes. */}
          A list of all ingredients that affected production activities that involve the recalled lot codes.
        </Typography>
      </Grid>
      {makeProductionTables()}
    </Grid>
  )
}

RecallIngredientList.propTypes = {
  ingredients: PropTypes.arrayOf(PropTypes.object),
  skus: PropTypes.objectOf(PropTypes.object),
  units: PropTypes.objectOf(PropTypes.object),
  disabled: PropTypes.bool,
  setShouldClose: PropTypes.func,
  closeModal: PropTypes.func,
}