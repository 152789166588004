class FFinishedInventoryAPI {
    constructor(api, version) {
      this.api = api;
      this.version = version;
    }
  
    listFFinishedInventory(id) {
      // if (typeof id === 'number') {
      //   return this.api.get(`${this.version}/f_production/${id}/`);
      // }
      return this.api.get(`${this.version}/f_finished_inventory/`)
    }
  
    // createFProduction(payload) {
    //   return this.api.post(`${this.version}/f_finished_inventory/`, payload);
    // }
  
    // updateFProduction(payload) {
    //   return this.api.put(`${this.version}/f_finished_inventory/${payload.id}/`, payload);
    // }
  
    // deleteFProduct(id) {
    //   return this.api.put(`${this.version}/f_finished_inventory/${id}/soft_delete_action/`);
    // }

    adjustFFinishedInventory(payload) {
        return this.api.patch(`${this.version}/f_finished_inventory/${payload.id}/update-unit/`,payload);
    }

    getFFinishedInventoryLotcodes(id) {
      return this.api.get(`${this.version}/f_finished_inventory/${id}/lot-codes/`);
    }
  
  }
  
  export default FFinishedInventoryAPI;