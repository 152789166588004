import React, { useState, useEffect } from 'react';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Traceability from "../Traceability";
import TraceabilityUtils from "../TraceabilityUtils";
import Message from "../../Components/Message";
import CircularProgress from "@material-ui/core/CircularProgress";
import AutocompleteSD from "../../Components/AutocompleteSD";
import CancelIcon from '@material-ui/icons/Cancel';
import ItemTable from '../ItemTable/ItemBody';
import ItemModal from '../Orders/ItemModal';
import PropTypes from 'prop-types';
import { StylesContext } from "../../App";

const utils = new TraceabilityUtils();

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

export default function ShippingForm(props) {
  const classes = React.useContext(StylesContext);

  const [loading, setLoading] = useState(true);
  const [shipping, setShipping] = useState(false);
  const [message, setMessage] = useState({ ...EMPTY_MESSAGE });
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);
  const [lineInfo, setLineInfo] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [customerAddress, setCustomerAddress] = useState({});

  useEffect(() => {
    if (shipping && Object.keys(props.shipping).length == 2 && props.shipping.status == 'Draft') {
      //This is to fix the very niche problem of setShouldClose causing the parents of this to re-render, re-passing an empty shipping form
      //That this can't tell is meant to be the same form and overriding the users first input each time they create a new record. 
      return;
    }
    setLineInfo([
      { key: 'product', title: 'Product Name', type: 'select', choices: props.finishInventory.filter((item) => item.total_unit_value != "0.00").map((item) => item.product), choiceDisplay: 'name' },
      { key: 'in_stock', title: 'Qty in Stock', type: 'display', },
      { key: 'unit_value', title: 'Unit Value', type: 'numeric', },
      { key: 'unit_choices', title: 'Unit Choice', type: 'select', choices: Object.values(props.units), choiceDisplay: 'full_name' },
    ])

    let newShipping = { ...props.shipping };
    // console.log("newShipping >> ", newShipping)
    newShipping.customer = !!newShipping.customer ? newShipping.customer : null;
    newShipping.customer_address = !!newShipping.customer_address ? newShipping.customer_address : null;

    let customerAdress = Object.values(props.customerAddressArr).find(item => item.pk == newShipping.customer_address);
    setCustomerAddress(customerAdress);
    
    if (!!newShipping.shipment_products) {
      newShipping.new_shipment_products = newShipping.shipment_products.map((shipmentProduct) => {

        let foundProduct = null;
        if (!!shipmentProduct?.product_detail?.id) {
          foundProduct = props.finishInventory.find(item => item.product.id == shipmentProduct.product_detail.id);
        }

        return {
          product: shipmentProduct.product_detail,
          total_unit_value: parseFloat(foundProduct?.total_unit_value),
          base_unit_type: props.units[foundProduct?.base_unit_type],
          in_stock: foundProduct ? foundProduct.total_unit_value + ' ' + props.units[foundProduct.base_unit_type].abbreviation : 0,
          unit_value: !!shipmentProduct.total_unit_value ? parseFloat(shipmentProduct.total_unit_value) : 0,
          unit_choices_arr: foundProduct ? foundProduct.unit_choices.map((unit) => !!props.units[unit] ? props.units[unit] : '') : [],
          unit_choices: !!props.units[shipmentProduct.total_unit_type] ? props.units[shipmentProduct.total_unit_type] : {},
        };
      })
    }

    if (!newShipping.shipment_date && !newShipping.id) {
      newShipping.shipment_date = new Date().toISOString().substr(0, 10);
    }
    // console.log("newShipping >> 22 >> ", newShipping)
    setShipping({ ...newShipping });

    setLoading(false)
  }, [props.shipping, props.finishInventory, props.units])


  function getCustomerAddress(customer){
    if (!!customer && !!props.customerAddressArr) {
      let customerAddress = Object.values(props.customerAddressArr).filter(address => address.customer == customer);

      // let pkIndexedcustomerAddress = {};
      // customerAddress.forEach((customerObject) => {
      //   pkIndexedcustomerAddress[customerObject.pk] = { ...customerObject };
      // });
      // console.log("pkIndexedcustomerAddress >> ",pkIndexedcustomerAddress);
      return customerAddress;
    }
    return [];
  }
  
  /* We can't delete directly with this reference, so it's quicker to delete by just adding an ignore
    flag and not send those lines to the BE.
  */
  function deleteLine(lineData, setLineData, indexToDelete) {
    if (window.confirm('Are you sure you want to delete ' + lineData.product.name + '?')) {
      let newShipping = { ...shipping };

      if (indexToDelete >= 0 && indexToDelete < newShipping.new_shipment_products.length) {
        newShipping.new_shipment_products.splice(indexToDelete, 1);
      }

      setShipping(newShipping);

    }
  }

  const actions = [
    { icon: (<CancelIcon />), func: deleteLine },
  ]

  function setLineItems(newLineItems) {
    let newShipping = { ...shipping };
    newShipping.new_shipment_products = newLineItems;

    setShipping(newShipping);
  }

  function handleAddItem(newItem) {
    let new_shipment_products = [...shipping.new_shipment_products.filter(new_shipment_product => !new_shipment_product.delete), { product: {}, in_stock: '', unit_value: 0, unit_type: {}, unit_choices: [], lot_codes: '' }];
    setLineItems(new_shipment_products);
  }

  function mergeDuplicateProducts() {
    // console.log("shipping.new_shipment_products >> ", shipping.new_shipment_products);

    const productMap = new Map();
    const order = [];

    shipping.new_shipment_products.forEach((item) => {
      const key = item.product.id;
      if (productMap.has(key)) {
        const existingItem = productMap.get(key);
        if (existingItem.unit_choices.pk === item.unit_choices.pk) {
          existingItem.unit_value += item.unit_value; // Sum the unit_value
        } else {
          // Handle unit conversion logic if needed
          if (existingItem.unit_choices.is_base) {
            existingItem.unit_value += item.unit_value * parseFloat(item.unit_choices.ratio_to_base);
          } else if (item.unit_choices.is_base) {
            existingItem.unit_value = existingItem.unit_value * parseFloat(existingItem.unit_choices.ratio_to_base) + item.unit_value;
            existingItem.unit_choices = item.unit_choices; // Update unit_choices to the base one
          } else {
            // Convert both to base unit value
            const existingRatio = parseFloat(existingItem.unit_choices.ratio_to_base);
            const itemRatio = parseFloat(item.unit_choices.ratio_to_base);

            const existingValueInBase = existingItem.unit_value * existingRatio;
            const newValueInBase = item.unit_value * itemRatio;

            // Sum the converted values
            existingItem.unit_value = existingValueInBase + newValueInBase;

            const baseUnitChoice = Object.values(props.units).find(unit =>
              unit.unit_type === existingItem.unit_choices.unit_type && unit.is_base
            );

            existingItem.unit_choices = !!baseUnitChoice && !!baseUnitChoice.pk ? baseUnitChoice : {};

          }
        }
      } else {
        productMap.set(key, { ...item });
        order.push(key); // Preserve the order of product IDs
      }
    });

    const mergedProducts = order.map(id => productMap.get(id)); // Create ordered merged list

    // console.log("mergedProducts >> ", mergedProducts)
    return mergedProducts;
    // setShipping({ ...shipping, new_shipment_products: mergedProducts });
  }

  function validateForm() {
    setSubmitAttempted(true);
    let mergedProducts = mergeDuplicateProducts();

    if (!shipping.customer) {
      setMessage({ open: true, message: 'Please specifiy Customer.', status: 'error' });
      return false;
    }

    if (!shipping.customer_address) {
      setMessage({ open: true, message: 'Please specifiy Customer Address.', status: 'error' });
      return false;
    }

    if (!shipping.shipment_date) {
      setMessage({ open: true, message: 'Please specifiy Shipment Date.', status: 'error' });
      return false;
    }

    if (mergedProducts.length < 1) {
      setMessage({ open: true, message: 'Shipments must have at least one line item.', status: 'error' });
      return false;
    }

    // const productIds = shipping.new_shipment_products.map(row => row.product?.id);
    // const hasDuplicates = new Set(productIds).size !== productIds.length;
    // if (hasDuplicates) {
    //   setMessage({ open: true, message: 'Duplicate products found in line items.', status: 'error' });
    //   return false;
    // }

    var linesValidated = true;
    mergedProducts.forEach((row, i) => {
      if (!lineValidation(row, i)) {
        linesValidated = false;
      }
    })

    return linesValidated;
  }

  function lineValidation(row, i) {
    if (!row.product || !row.product.id) {
      setMessage({ open: true, message: 'Please specify an item on row ' + i + '.', status: 'error' });
      return false;
    }

    let temp = parseFloat(row?.total_unit_value) / parseFloat(row.unit_choices.ratio_to_base);
    row.unit_value = typeof row.unit_value === 'string' ? row.unit_value : String(row.unit_value);
    if (!!temp && (temp < parseFloat(row.unit_value))) {
      setMessage({ open: true, message: 'Please specify an valid amount for ' + row.product.name + '.', status: 'error' });
      return false;
    }

    if (!row.unit_value || isNaN(row.unit_value) || row.unit_value.trim() === '') {
      setMessage({ open: true, message: 'Please specify an amount for ' + row.product.name + '.', status: 'error' });
      return false;
    }

    if (!row.unit_choices) {
      setMessage({ open: true, message: 'Please specify unit type for ' + row.product.name + '.', status: 'error' });
      return false;
    }

    return true;
  }

  function handleSubmit(saveAndContinue) {
    setSubmitting(true);
    if (!validateForm()) {
      setSubmitting(false);
      return;
    }

    let formattedShipping = { ...shipping };
    formattedShipping.status = !!saveAndContinue ? 'PICKING' : "DRAFT";

    let mergedProducts = mergeDuplicateProducts();
    formattedShipping.shipment_products = mergedProducts.map((item) => {
      return {
        "product": item.product.id,
        "total_unit_type": item.unit_choices.pk,
        "total_unit_value": item.unit_value,
      }
    })
    delete formattedShipping.new_shipment_products;
    delete formattedShipping.confirmation_signature;
    delete formattedShipping.organization;

    // console.log("formattedShipping >> ", formattedShipping)
    const api = new Traceability().getFShippingAPI();
    if (formattedShipping.id) {
      api.updateFShipping(formattedShipping).then(response => {
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }

        let newShipping = { ...response.data };
        newShipping.customer = !!newShipping.customer ? newShipping.customer : null;
        newShipping.customer_address = !!newShipping.customer_address ? newShipping.customer_address : null;
        
        let customerAdress = Object.values(props.customerAddressArr).find(item => item.pk == newShipping.customer_address);
        setCustomerAddress(customerAdress);
        
        if (!!newShipping.shipment_products) {
          newShipping.new_shipment_products = newShipping.shipment_products.map((shipmentProduct) => {

            let foundProduct = null;
            if (!!shipmentProduct?.product_detail?.id) {
              foundProduct = props.finishInventory.find(item => item.product.id == shipmentProduct.product_detail.id);
            }

            return {
              product: shipmentProduct.product_detail,
              total_unit_value: parseFloat(foundProduct?.total_unit_value),
              base_unit_type: props.units[foundProduct?.base_unit_type],
              in_stock: foundProduct ? foundProduct.total_unit_value + ' ' + props.units[foundProduct.base_unit_type].abbreviation : 0,
              unit_value: !!shipmentProduct.total_unit_value ? parseFloat(shipmentProduct.total_unit_value) : 0,
              unit_choices_arr: foundProduct ? foundProduct.unit_choices.map((unit) => !!props.units[unit] ? props.units[unit] : '') : [],
              unit_choices: !!props.units[shipmentProduct.total_unit_type] ? props.units[shipmentProduct.total_unit_type] : {},
            };
          })
        }

        setShipping(newShipping);
        setSubmitAttempted(false);
        setMessage({
          open: true,
          message: 'Saved Successfully',
          status: 'success',
        });
        if (props.onSave) {
          props.onSave(response);
        }

        setSubmitting(false);

      }).catch(error => {
        setSubmitting(false);
        setMessage({ open: true, message: 'Save Failed: ' + utils.formatError(error), status: 'error' });
      });
    }
    else {
      api.createFShipping(formattedShipping).then(response => {
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }

        let newShipping = { ...response.data };
        newShipping.customer = !!newShipping.customer ? newShipping.customer : null;
        newShipping.customer_address = !!newShipping.customer_address ? newShipping.customer_address : null;

        if (!!newShipping.shipment_products) {
          newShipping.new_shipment_products = newShipping.shipment_products.map((shipmentProduct) => {

            let foundProduct = null;
            if (!!shipmentProduct?.product_detail?.product?.id) {
              foundProduct = props.finishInventory.find(item => item.product.id == shipmentProduct.product_detail.product.id);
            }

            return {
              product: shipmentProduct.product_detail.product,
              total_unit_value: parseFloat(foundProduct?.total_unit_value),
              base_unit_type: props.units[foundProduct?.base_unit_type],
              in_stock: foundProduct ? foundProduct.total_unit_value + ' ' + props.units[foundProduct.base_unit_type].abbreviation : 0,
              unit_value: !!shipmentProduct.product_detail.unit_value ? parseFloat(shipmentProduct.product_detail.unit_value) : 0,
              unit_choices_arr: foundProduct ? foundProduct.unit_choices.map((unit) => !!props.units[unit] ? props.units[unit] : '') : [],
              unit_choices: !!props.units[shipmentProduct.product_detail.unit_type] ? props.units[shipmentProduct.product_detail.unit_type] : {},
            };
          })
        }

        setShipping(newShipping);
        setSubmitAttempted(false);
        setMessage({
          open: true,
          message: 'Saved Successfully',
          status: 'success',
        });
        if (props.onSave) {
          props.onSave(response);
        }
        setSubmitting(false);

      }).catch(error => {
        setSubmitting(false);
        setMessage({ open: true, message: 'Save Failed: ' + utils.formatError(error), status: 'error' });
      });
    }

    if (props.setShouldClose) {
      props.setShouldClose(true);
    }
  }

  function handleTextFieldChange(event) {

    if (props.setShouldClose) {
      props.setShouldClose(false);
    }

    const property = event.target.name;
    const value = event.target.value;

    setShipping({ ...shipping, [property]: value });
  }

  function handleAutoCompleteChange(newKey, newValue) {
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }

    setShipping({ ...shipping, [newKey]: newValue });
  }

  function cancel() {
    setShipping(props.shipping ? props.shipping : {});

    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    if (props.closeModal) {
      props.closeModal();
    }
  }

  function formatShippingInstances(new_shipment_products) {
    // console.log("formatShippingInstances >> new_shipment_products >> ", new_shipment_products)
    return new_shipment_products.map((new_shipment_product) => {
      let foundProduct = null;
      if (!!new_shipment_product?.product?.id) {
        foundProduct = props.finishInventory.find(item => item.product.id == new_shipment_product.product.id);
      }

      return {
        product: new_shipment_product.product,
        total_unit_value: parseFloat(foundProduct?.total_unit_value),
        base_unit_type: props.units[foundProduct?.base_unit_type],
        in_stock: foundProduct ? foundProduct.total_unit_value + ' ' + props.units[foundProduct.base_unit_type].abbreviation : 0,
        unit_value: !!new_shipment_product.unit_value ? parseFloat(new_shipment_product.unit_value) : 0,
        unit_choices_arr: foundProduct ? foundProduct.unit_choices.map((unit) => !!props.units[unit] ? props.units[unit] : '') : [],
        unit_choices: new_shipment_product.unit_choices,
        lot_codes: ''
      };
    })
  }

  // console.log("props.customers >> ",props.customers);
  // console.log("customerAddress >> ",customerAddress);
  // console.log("shipping >> ", shipping);
  // console.log("props.shipping >> ", props.shipping);
  // console.log("props.units >> ", props.units)
  // console.log("props.finishInventory >> ",props.finishInventory)
  return (
    <>
      {(!props.shipping || loading) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }
      {(props.shipping && !loading) &&
        <>
          <Paper elevation={0} className={classes.generalFormPaperFieldHolder} style={{ paddingTop: '0px', marginTop: '32px' }}>
            <Grid container spacing={3} className={classes.generalContainerGridBody}>

              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  {'Editing Shipment'}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Customer
                </Typography>
                <AutocompleteSD
                  value={shipping.customer}
                  id="customer"
                  className={classes.generalFormTextField}
                  optionType={'pkIndexingToObjects'}
                  options={Object.keys(props.customers).map((key) => { return props.customers[key].id })}
                  choices={props.customers}
                  getOptionLabel={(option) => {
                    return (option && props.customers[option]?.name) ? props.customers[option]?.name : 'No Name'
                  }}
                  onChange={(emptyEvent, newValue) => { setCustomerAddress({}); handleAutoCompleteChange('customer', newValue) }}
                  renderInput={(params) => <TextField {...params} variant="outlined" error={submitAttempted && !shipping.customer} />}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Customer Address
                </Typography>
                <AutocompleteSD
                  value={customerAddress}
                  id="customer_address"
                  className={classes.generalFormTextField}
                  // optionType={'pkIndexingToObjects'}
                  // options={Object.keys(customerAddress).map((key) => { return customerAddress[key].pk })}
                  // choices={customerAddress}
                  // getOptionLabel={(option) => {
                  //   console.log("option >> ",customerAddress[option]);
                  //   return (option && customerAddress[option]?.address) ? customerAddress[option]?.address : 'No Name'
                  // }}
                  options={getCustomerAddress(shipping.customer)}
                  optionType={"rawObjects"}
                  getOptionLabel={(option) => option.address ? option.address : 'No Name'}
                  onChange={(emptyEvent, newValue) => { handleAutoCompleteChange('customer_address', newValue ? newValue.pk : null) }}
                  renderInput={(params) => <TextField {...params} variant="outlined" error={submitAttempted && !shipping.customer_address} />}
                  disabled={shipping.status == 'Shipped' || props.disabled}
                  />
              </Grid>

              <Grid item xs={4}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Shipment Date
                </Typography>
                <TextField
                  fullWidth
                  className={classes.generalFormTextField}
                  name="shipment_date"
                  type="date"
                  variant="outlined"
                  value={shipping.shipment_date}
                  onChange={handleTextFieldChange}
                  disabled={shipping.status == 'COMP' || props.disabled}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider variant="middle" />
              </Grid>

              <Grid item xs={12}>
                <ItemTable
                  tableData={formatShippingInstances(shipping.new_shipment_products)}
                  setLineData={setLineItems}
                  lineInfo={lineInfo}
                  actions={actions}
                  handleAddItem={(shipping.status == 'Shipped' || props.disabled) ? false : () => { handleAddItem() }}
                  ineditable={shipping.status == 'Shipped' || shipping.status == 'CANC' || shipping.status == 'COMP' || props.disabled}
                />
              </Grid>

            </Grid>
          </Paper>

          {/* This Grid serves as the footer element. */}
          <Paper elevation={0} className={classes.generalFormPaperStickyFooter}>
            <Grid container spacing={3} className={classes.generalContainerGridFoot}>
              <Grid item container xs={12} alignItems="center" justify="flex-end">
                <Button
                  //variant="outlined"
                  color="secondary"
                  style={{ marginLeft: "8px" }}
                  onClick={props.closeModal ? props.closeModal : cancel}
                  disabled={submitting}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "8px" }}
                  onClick={() => { handleSubmit(false) }}
                  disabled={shipping.status == 'Shipped' || shipping.status == 'CANC' || shipping.status == 'COMP' || props.disabled || submitting}
                >
                  Save Changes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "8px" }}
                  onClick={() => { handleSubmit(true) }}
                  disabled={props.disabled || submitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Paper>

        </>
      }

      <Dialog open={addItemModalOpen} onClose={() => { setAddItemModalOpen(false) }} maxWidth={"lg"}>
        <DialogContent>
          <ItemModal
            skuInstance={{}}
            closeModal={() => { setAddItemModalOpen(false) }}
            // skus={props.skus}
            handleAddItem={handleAddItem}
            disabled={shipping.status == 'Shipped' || shipping.status == 'CANC' || shipping.status == 'COMP' || props.disabled}
          />
        </DialogContent>
      </Dialog>

      <Message
        open={message.open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="left"
        handleClose={() => { setMessage({ ...message, open: false }) }}
      />
    </>
  )
}

ShippingForm.propTypes = {
  setShouldClose: PropTypes.func,
  setReturnMessage: PropTypes.func,
  shipping: PropTypes.object,
  closeModal: PropTypes.func,
  customers: PropTypes.objectOf(PropTypes.object),
  cancel: PropTypes.func,
  user: PropTypes.object.isRequired,
  onSave: PropTypes.func,
}