import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Traceability from "../Traceability";
import TraceabilityUtils from "../TraceabilityUtils";
import Message from "../../Components/Message";
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Paper from "@material-ui/core/Paper";
import AutocompleteSD from "../../Components/AutocompleteSD";
import CancelIcon from '@material-ui/icons/Cancel';
import { StylesContext } from "../../App";

export default function FinishedInventoryAdjustmentForm(props) {
  const classes = React.useContext(StylesContext);
  const utils = new TraceabilityUtils();
  const finishedInventoryApi = new Traceability().getFFinishedInventoryAPI();
  const [inventoryAdjustment, setInventoryAdjustment] = useState({});
  const [selectedIngredient, setSelectedIngredient] = useState({});
  const [ingredientLotCodes, setIngredientLotCodes] = useState([]);
  const [selectedIngredientLotCode, setSelectedIngredientLotCode] = useState({});
  const [selectedUnitType, setSelectedUnitType] = useState({});
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [message, setMessage] = useState(utils.emptyMessage());
  const [validSubmit, setValidSubmit] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  // const [dummyKey, setDummyKey] = useState(0);
  const [unitTypeOptions, setUnitTypeOptions] = useState([]);

  useEffect(() => {
    if (
      !selectedUnitType.pk ||
      (!inventoryAdjustment.new_value && inventoryAdjustment.new_value !== 0)
    ) {
      setStatusMessage('');
      setValidSubmit(false)
      return;
    }

    setValidSubmit(true);

  }, [selectedUnitType, inventoryAdjustment?.new_value])

  useEffect(() => {
    if (inventoryAdjustment.formulation) {
      // Filter lot codes based on selected ingredient
      console.log("props.formulations >> ", props.formulations)
      console.log("inventoryAdjustment.formulation >> ",inventoryAdjustment.formulation)
      const selectedIngredient = props.formulations[inventoryAdjustment.formulation];
      console.log("selectedIngredient >> ",selectedIngredient)
      setSelectedIngredient(selectedIngredient);
      inventoryAdjustment.id = selectedIngredient.id;
      console.log("selectedIngredient >> 11 >> ", selectedIngredient, typeof (selectedIngredient.id))
      // inventoryAdjustment.unit_value = selectedIngredient.unit_value;
      if (selectedIngredient) {
        finishedInventoryApi.getFFinishedInventoryLotcodes(selectedIngredient.id).then(e => {
          console.log("flotcode >> ", e.data);
          setIngredientLotCodes(e.data);
        }).catch(e => {
          //console.log(e);
        })
      }
      // console.log("props.units >> ", props.units)
      const selectedUnit = props.units[selectedIngredient.unit_type];
      // console.log("selectedUnit >> ",selectedUnit)
      const filteredUnitTypes = Object.values(props.units).filter(unit => unit.unit_type == selectedUnit.unit_type);
      // const filteredUnitTypes = Object.entries(props.units)
      //   .filter(([key, unit]) => unit.unit_category === selectedUnit.unit_category)  // Match unit_category
      //   .reduce((acc, [key, unit]) => {
      //     acc[key] = unit;  // Return a new object with the filtered units
      //     return acc;
      //   }, {});
      // console.log("filteredUnitTypes >> ", filteredUnitTypes)
      // const filteredUnitTypes = Object.keys(props.units).filter(unitType => 
      //   props.units[unitType].unit_type === selectedIngredient.unit_metric
      // );
      // Update AutocompleteSD options with filtered unit types
      setUnitTypeOptions(filteredUnitTypes);

    }
  }, [inventoryAdjustment.formulation, props.formulations]);

  useEffect(() => {
    // Reset new_value and unit_value when ingredient changes
    if (inventoryAdjustment.formulation) {
      setInventoryAdjustment(prevState => ({
        ...prevState,
        new_value: '',
        unit_value: ''
      }));
      setSelectedIngredientLotCode({});
      setSelectedUnitType({});
    }
  }, [inventoryAdjustment.formulation]);

  function validateForm() {
    setSubmitAttempted(true);

    if (!inventoryAdjustment.formulation) {
      setMessage({ open: true, message: 'Please enter the formulation name.', status: 'error' });
      return false;
    }
    console.log("selectedIngredientLotCode >> ",selectedIngredientLotCode)
    if (!selectedIngredientLotCode.lot_code) {
      setMessage({ open: true, message: 'Please enter the external lot code.', status: 'error' });
      return false;
    }

    if (!inventoryAdjustment.new_value || isNaN(inventoryAdjustment.new_value) || inventoryAdjustment.new_value.trim() === '') {
      setMessage({ open: true, message: 'Please enter the valid new value on hand.', status: 'error' });
      return false;
    }

    if (!selectedUnitType.pk) {
      setMessage({ open: true, message: 'Please enter the unit type.', status: 'error' });
      return false;
    }

    return true;
  }

  function handleSubmit(saveAndContinue) {
    if (!validateForm()) {
      return;
    }
    console.log("inventoryAdjustment >> ",inventoryAdjustment)
    const api = new Traceability().getFFinishedInventoryAPI();
    let formatedData = {};
    formatedData.id = inventoryAdjustment.id;
    formatedData.unit_value = inventoryAdjustment.new_value;
    formatedData.unit_type = selectedUnitType.pk;
    console.log("formatedData >> ",formatedData)

    api.adjustFFinishedInventory(formatedData).then(response => {
      if (props.setShouldClose) {
        props.setShouldClose(true);
      }

      setSubmitAttempted(false);
      if (props.onSave) {
        props.onSave(response, saveAndContinue);
      }
      setMessage({ open: true, message: 'Saved Successfully', status: 'success' });
      if (props.closeModal) {
        props.closeModal();
      }
    }).catch(error => {
      setMessage({ open: true, message: 'Server Error: ' + utils.formatError(error), status: 'error' });
      //console.log('Error Response: ' + JSON.stringify(error));
    });
  }


  function handleTextFieldChange(event) {
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }

    const property = event.target.name;
    const value = event.target.value;

    setInventoryAdjustment({ ...inventoryAdjustment, [property]: value });
  }

  function handleAutoCompleteChange(newKey, newValue) {
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }

    if (newKey === 'lot_code') {
      const selectedLotCode = ingredientLotCodes.find(obj => obj.id == newValue);
      console.log("selectedLotCode >> ",selectedLotCode);
      console.log("props.units >>> ",props.units)
      const matchingUnit = props.units[parseInt(selectedLotCode.unit_type)];
      console.log("matchingUnit >> ",matchingUnit)
      const abbreviation = matchingUnit ? matchingUnit.abbreviation : '';
      console.log("sumit >> newKey >> ",newKey,">> newValue >> ",newValue)
      if (selectedLotCode) {
        setSelectedIngredientLotCode(selectedLotCode);
        setSelectedUnitType(matchingUnit)
        setInventoryAdjustment({
          ...inventoryAdjustment,
          [newKey]: newValue,
          id: selectedLotCode.id,
          unit_value: selectedLotCode.unit_value + ' ' + abbreviation // Set the unit_value from the selected lot code
        });
      } else {
        setInventoryAdjustment({
          ...inventoryAdjustment,
          [newKey]: newValue,
          unit_value: '' // Reset unit_value if no lot code is selected
        });
      }
    } else if (newKey === 'unit_type') {
      console.log("unit_type >> newValue >> ", newValue);
      setSelectedUnitType(newValue);
    } else {
      console.log("else >> inventoryAdjustment >> ",inventoryAdjustment)
      console.log("else >> inventoryAdjustment >> newKey",newKey,newValue)
      setInventoryAdjustment({ ...inventoryAdjustment, [newKey]: newValue });
    }


    // setInventoryAdjustment({ ...inventoryAdjustment, [newKey]: newValue});

  }

  function cancel() {
    setInventoryAdjustment(props.inventoryAdjustment ? props.inventoryAdjustment : {});

    if (props.closeModal) {
      props.closeModal();
    }
  }
  console.log("inventoryAdjustment >> ",inventoryAdjustment)
  console.log("unitTypeOptions >> ",unitTypeOptions)
  return (
    <form onSubmit={props.handleSubmit ? props.handleSubmit : handleSubmit}>

      <Grid container spacing={3} className={classes.generalContainerGridHead}>
        <Grid item xs={11}>
          <Typography variant="h6" noWrap className={classes.generalFormHeaderTypography}>
            Finished Inventory Adjustment
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton style={{ float: 'right' }} onClick={props.closeModal ? props.closeModal : cancel}>
            <CancelIcon style={{ float: 'right' }} />
          </IconButton>
        </Grid>
      </Grid>


      <Grid container spacing={3} className={classes.generalContainerGridBody} style={{ marginTop: '16px' }}>

        <Grid item xs={12}>
          <Typography variant="h5" noWrap className={classes.generalFormSubsectionHeader}>
            Adjust Finished Inventory
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} square className={classes.generalFormPaperContainerApprovedInfo}>
            <Grid container spacing={3} className={classes.generalContainerGrid}>
              <Grid item xs={6}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Formulation Name
                </Typography>
                <AutocompleteSD
                  // key={dummyKey}
                  value={inventoryAdjustment.formulation}
                  id="formulation"
                  className={classes.generalFormTextField}
                  optionType={'pkIndexingToObjects'}
                  options={Object.keys(props.formulations)}
                  choices={props.formulations}
                  getOptionLabel={(option) => {
                    return props.formulations[option] ? props.formulations[option].name : 'No Name'
                  }}
                  onChange={(emptyEvent, newValue) => {
                    setSelectedIngredientLotCode({});
                    setSelectedUnitType({});
                    setIngredientLotCodes([])
                    handleAutoCompleteChange('formulation', newValue)
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" error={submitAttempted && !inventoryAdjustment.formulation} />}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Lot code
                </Typography>
                <AutocompleteSD
                  // key={dummyKey}
                  // value={inventoryAdjustment.lot_code}
                  // id="lot_code"
                  // className={classes.generalFormTextField}
                  // optionType={'pkIndexingToObjects'}
                  // options={Object.keys(ingredientLotCodes)}
                  // choices={ingredientLotCodes}
                  // getOptionLabel={(option) => {
                  //   return ingredientLotCodes[option] ? ingredientLotCodes[option].lot_code : 'No COde'
                  // }}
                  value={selectedIngredientLotCode}
                  id="lot_code"
                  className={classes.generalFormTextField}
                  optionType={'rawObjects'}
                  options={ingredientLotCodes}
                  // choices={ingredientLotCodes}
                  getOptionLabel={(option) => {
                    return option ? option.lot_code : 'No Code'
                  }}
                  onChange={(emptyEvent, newValue) => { handleAutoCompleteChange('lot_code', newValue ? newValue.id : null) }}
                  renderInput={(params) => <TextField {...params} variant="outlined" error={submitAttempted && !selectedIngredientLotCode.id} />}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Existing value
                </Typography>
                <TextField
                  fullWidth
                  className={classes.generalFormTextField}
                  name="unit_value"
                  variant="outlined"
                  value={inventoryAdjustment.unit_value}
                  onChange={handleTextFieldChange}
                  error={submitAttempted && !inventoryAdjustment.unit_value}
                  disabled={props.disabled || true}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.generalFormTypographyHeader}>
                  New Value on Hand
                </Typography>
                <TextField
                  fullWidth
                  className={classes.generalFormTextField}
                  name="new_value"
                  variant="outlined"
                  value={inventoryAdjustment.new_value}
                  onChange={handleTextFieldChange}
                  error={submitAttempted && !inventoryAdjustment.new_value}
                  disabled={props.disabled}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.generalFormTypographyHeader}>
                  Unit Type
                </Typography>
                <AutocompleteSD
                  // key={dummyKey}
                  // value={inventoryAdjustment.unit_type}
                  // id="unit_type"
                  // className={classes.generalFormTextField}
                  // optionType={'pkIndexingToObjects'}
                  // options={unitTypeOptions}
                  // choices={props.units}
                  // getOptionLabel={(option) => {
                  //   return props.units[option] ? props.units[option].full_name : 'No name'
                  // }}
                  value={selectedUnitType}
                  id="unit_type"
                  className={classes.generalFormTextField}
                  optionType={'rawObjects'}
                  options={unitTypeOptions}
                  // choices={ingredientLotCodes}
                  getOptionLabel={(option) => {
                    return option ? option.full_name : 'No name'
                  }}
                  disabled={selectedIngredientLotCode.is_used_in_shipment}
                  onChange={(emptyEvent, newValue) => { handleAutoCompleteChange('unit_type', newValue ? newValue : null) }}
                  renderInput={(params) => <TextField {...params} variant="outlined" error={submitAttempted && !selectedUnitType.pk} />}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.generalFormTypographyHeader}>
                  {statusMessage}
                </Typography>
              </Grid>


            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Paper elevation={0} square className={classes.generalFormPaperStickyFooterApprovedInfo}>
        <Grid container spacing={3} className={classes.generalContainerGridFoot}>
          <Grid item container xs={12} alignItems="center" justify="flex-end">
            <Button
              //variant="outlined"
              color="secondary"
              style={{ marginLeft: "8px" }}
              onClick={props.closeModal ? props.closeModal : cancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "8px" }}
              onClick={() => { handleSubmit(true) }}
              disabled={!validSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Message
        open={message.open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="left"
        handleClose={() => { setMessage({ ...message, open: false }) }}
      />
    </form>
  )
}

FinishedInventoryAdjustmentForm.propTypes = {
  customer: PropTypes.object,
  handleSubmit: PropTypes.func,
  user: PropTypes.object.isRequired,
  activeTraceability: PropTypes.object.isRequired,
  setShouldClose: PropTypes.func,
  onSave: PropTypes.func,
  closeModal: PropTypes.func,
  cancel: PropTypes.func,
}