import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import AvatarComponent from "../../Components/AvatarComponent";
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTheme } from "@material-ui/styles";
import TraceabilityUtils from "../TraceabilityUtils";
import { StylesContext } from "../../App";
import Traceability from "Traceability/Traceability";
import Message from "Components/Message";


export default function RecallDisplayCard(props) {
  const utils = new TraceabilityUtils();
  const classes = React.useContext(StylesContext);
  const theme = useTheme();
  const [message, setMessage] = useState(utils.emptyMessage());

  function handleDeleteClick() {
    const confirmDelete = window.confirm("Are you sure you want to delete this recall?");
    if (confirmDelete) {
      const api = new Traceability().getFRecallAPI();
      if (props.recall.id) {
        api.deleteFRecall(props.recall.id).then(response => {
          setMessage({
            open: true,
            message: 'Recall deleted Successfully',
            status: 'success',
          });
          if (props.onDelete) {
            props.onDelete(props.recall.id);
          }
        }).catch(error => {
          console.log("delete recall >> error >> ", error);
          setMessage({ open: true, message: 'Delete Failed: ' + utils.formatError(error), status: 'error' });
        });
      }
    }
  }

  function getQuantityRecalledDisplay(lotCodeObj, i) {
    let displayText = lotCodeObj.recalled_amount + ' ' + lotCodeObj.recalled_amount_unit_type.abbreviation;
    displayText = displayText + ' of ' + lotCodeObj.product_name;

    if (i > 0) {
      displayText = ', ' + displayText;
    }

    return displayText;
  }

  return (
    <>
    <Card elevation={0} className={classes.recallDisplayCard}>
      <CardHeader
        avatar={
          <AvatarComponent rounded user={props.recall.user}></AvatarComponent>
        }
        action={<>
          <Button
            //variant="outlined"
            color="primary"
            style={{ margin: "8px" }}
            variant="contained"
            onClick={() => {
              props.setRecallSelected(props.recall);
              props.setRecallFormOpen(true);
            }}
          >
            VIEW REPORT
          </Button>
          <Button
            color="primary"
            variant="outlined"
            style={{ margin: "8px" }}
            onClick={handleDeleteClick}
          >
            DELETE
          </Button>
          {/* <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton> */}
        </>}
        title={props.recall.recall_name}
        subheader={"Created: " + utils.dateObjToString(props.recall.recall_date)}
        style={{ borderBottom: 'solid 1px black' }}
      />

      <CardContent>
        <Grid container spacing={3} className={classes.recallDisplayContainerGrid}>

          <Grid item xs={6} className={classes.recallDisplayItemGrid}>
            <Typography style={{ color: theme.palette.textEmphasis.soft, fontFamily: 'Roboto' }}>
              <span style={{ color: theme.palette.textEmphasis.hard }}><b>Lots Recalled: </b></span>
              {props.recall.potentially_impacted_ingredient.map((lotCodeObj, i) => {
                return i > 0 ?
                  ', ' + lotCodeObj.external_lot_code : lotCodeObj.external_lot_code
              })}
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.recallDisplayItemGrid}>
            <Typography style={{ color: theme.palette.textEmphasis.soft, fontFamily: 'Roboto' }}>
              <span style={{ color: theme.palette.textEmphasis.hard }}><b>Recall Type: </b> </span>
              {props.recall.recall_type + ' Recall'}
            </Typography>
          </Grid>

          <Grid item xs={6} className={classes.recallDisplayItemGrid}>
            <Typography style={{ color: theme.palette.textEmphasis.soft, fontFamily: 'Roboto' }}>
              <span style={{ color: theme.palette.textEmphasis.hard }}><b>Products Recalled: </b></span>
              {props.recall.potentially_impacted_production.map((product, i) => {
                return i > 0 ?
                  ', ' + product.formulation.name : product.formulation.name
              })}
            </Typography>
          </Grid>
          {/* <Grid item xs={6} className={classes.recallDisplayItemGrid}>
            <Typography style={{color: theme.palette.textEmphasis.soft, fontFamily: 'Roboto'}}>
              <span style={{color: theme.palette.textEmphasis.hard}}><b>Qty Recalled: </b></span>
              { props.recall.internal_lot_codes.map((lotCodeObj, i) => {return getQuantityRecalledDisplay(lotCodeObj, i)}) }
            </Typography>
          </Grid> */}

          <Grid item xs={6} className={classes.recallDisplayItemGrid}>
            <Typography style={{ color: theme.palette.textEmphasis.soft, fontFamily: 'Roboto' }}>
              <span style={{ color: theme.palette.textEmphasis.hard }}><b>Shipments Affected: </b></span>
              {props.recall.potentially_impacted_shipping.length + ' shipments contain recalled lot(s)'}
            </Typography>
          </Grid>

        </Grid>

      </CardContent>
    </Card>
    <Message
    open={message.open}
    message={message.message}
    severity={message.status}
    vertical="bottom"
    horizontal="left"
    handleClose={() => { setMessage({ ...message, open: false }) }}
  />
  </>
  )
}

RecallDisplayCard.propTypes = {
  recall: PropTypes.object,
  user: PropTypes.object,
  closeModal: PropTypes.func,
  // skuInstance: PropTypes.object,
  skus: PropTypes.array,
  handleAddItem: PropTypes.func,
  setRecallSelected: PropTypes.func,
}