class FShippingAPI {
    constructor(api, version) {
        this.api = api;
        this.version = version;
    }

    listFShipping(id) {
        if (typeof id === 'number') {
            return this.api.get(`${this.version}/f_shipping/${id}/`);
        }
        return this.api.get(`${this.version}/f_shipping/`)
    }

    createFShipping(payload) {
        return this.api.post(`${this.version}/f_shipping/`, payload);
    }

    updateFShipping(payload) {
        return this.api.put(`${this.version}/f_shipping/${payload.id}/`, payload);
    }

    deleteFShipping(id) {
        return this.api.delete(`${this.version}/f_shipping/${id}/`);
    }

}

export default FShippingAPI;