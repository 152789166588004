import React, { useEffect, useContext, useState } from "react";
import { useHistory, useRouteMatch, useParams, Switch, Route, Link, matchPath, Redirect } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";

import CancelIcon from '@material-ui/icons/Cancel';
import LaunchIcon from '@material-ui/icons/Launch';

import Alert from "@material-ui/lab/Alert";

import Task from "./Task";
import EventWidget from "./EventWidget";
import API from "../../Api/Api";
import Prompt from "./Prompt";
import Search from '../../Components/Search';

import AddEventDialog from "./AddEventDialog";
import AddStandardsDialog from "./AddStandardsDialog";
import CustomRecordFields from "./CustomRecordFields";
import SanitizeHtml from "../../Components/SanitizeHtml";

import { makeStyles } from '@material-ui/core/styles';
import useDesktop from "../../hooks/useDesktop";

const useStyles = makeStyles({
  header: { fontStyle: 'Roboto', fontSize: '14px', fontWeight: 500, fontHeight: '21px' },
  title: { fontStyle: 'Roboto', fontSize: '16px', fontWeight: 500, fontHeight: '21px' },
})

export default function TaskManager({ activePlan, setActivePlan, readOnly, sideMenuOpen, closeSideMenu }) {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  const [addEventDialogOpen, setAddEventDialogOpen] = useState(false);
  const [addStandardsDialogOpen, setAddStandardsDialogOpen] = useState(false);

  const [taskSelected, setTaskSelected] = useState(null);

  const classes = useStyles();
  const match = useRouteMatch();
  const history = useHistory();

  const api = new API();
  const onDesktop = useDesktop();

  useEffect(() => {
    if (activePlan && activePlan.tasks) {
      setLoading(false);
      setTasks(activePlan.tasks);
    }
  }, [activePlan]);

  useEffect(() => {
    if (activePlan) {
      const matchedPath = matchPath(history.location.pathname, {
        path: '/plans/manager/tasks/:task_pk',
        exact: false,
        strict: false
      });

      if (matchedPath == null) {
        if (activePlan.tasks && activePlan.tasks.length) {
          history.replace(`/plans/manager/tasks/${activePlan.tasks[0].id}`);
        }
        return;
      }

      let task = activePlan.tasks.find(task => task.id === parseInt(matchedPath.params.task_pk, 10));
      if (task) {
        setTaskSelected(task);
      }
    }
  }, [history.location, activePlan]);

  function selectTask(task) {
    if (closeSideMenu) {
      closeSideMenu();
    }
    history.push(`${match.url}/${task.id}`);
  }

  function updateTask(task, saveAndContinue, save) {
    let oldIndex = tasks.findIndex((old) => { return old.id === task.id });

    if (oldIndex !== -1) {
      let updatedTasks = [...tasks];
      updatedTasks[oldIndex] = task;

      setTasks(updatedTasks);
      setTaskSelected(updatedTasks[oldIndex]);
      //setActivePlan({...activePlan, tasks: updatedTasks});

      if (save) {
        setActivePlan({ ...activePlan, tasks: updatedTasks });
      }

      if (saveAndContinue) {
        setActivePlan({ ...activePlan, tasks: updatedTasks });
        history.goBack();
      }

      setAlertOpen(true);

      return updatedTasks;
    }
  }

  function addTask(task, saveAndContinue) {
    let updatedTasks = [task, ...tasks];
    setTasks(updatedTasks);

    setTaskSelected(task);
    setActivePlan({ ...activePlan, tasks: updatedTasks });

    if (saveAndContinue) {
      history.goBack();
    }

    setAlertOpen(true);
  }

  function deleteTask(task) {
    let updatedTasks = [...tasks.filter(tsk => tsk.id !== task.id)];

    let updatedEvents = [...activePlan.events];
    for (let i = 0; i < updatedEvents.length; i++) {
      let updatedEvent = updatedEvents[i];
      updatedEvent.tasks = updatedEvent.tasks.filter(tsk => tsk !== task.id);
      updatedEvent.read_tasks = updatedEvent.read_tasks.filter(tsk => tsk.id !== task.id);
    }

    setTasks(updatedTasks);
    setTaskSelected(null);
    setActivePlan({ ...activePlan, tasks: updatedTasks, events: updatedEvents });

    history.goBack();
  }

  function getTaskCard(task) {
    let borderColor = (taskSelected && taskSelected.id === task.id) ? '#18BFF6' : "white";

    return (
      <Card key={task.id} elevation={0} variant="outlined" style={{ margin: '8px', width: '100%', borderColor: borderColor }}>
        <CardActionArea onClick={() => { selectTask(task) }} style={{ width: '100%', height: '100%' }}>
          <CardContent>
            {task.name}
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }

  const [standardSelected, setStandardSelected] = useState(null);
  function getStandardCard(standard) {
    return (
      <Card key={standard.id} elevation={0} style={{ width: '100%', padding: '0px', marginBottom: '16px' }}>
        <CardContent style={{ padding: '8px' }}>
          <Grid container>
            <Grid container item xs={11} alignItems="center" style={{ padding: '4px' }}>
              <Typography className={classes.header}>{standard.title}</Typography>
            </Grid>
            {!readOnly &&
              <Grid container item xs={1} alignItems="center" justify="flex-end" style={{ padding: '4px' }}>
                <IconButton onClick={() => { setStandardSelected(standard) }}>
                  <CancelIcon />
                </IconButton>
              </Grid>
            }
          </Grid>
          <Prompt
            agree={() => detachStandardAgree(standard)}
            disagree={detachStandardDisagree}
            open={(standardSelected && standardSelected.id) === standard.id}
            title="Detach Standard?"
            description="Are you sure you want to detach this standard? You can always add it back later."
          />
        </CardContent>
      </Card>
    )
  }

  function detachStandardAgree(standard) {
    if (standard) {
      detachStandard(standard);
    }

    setStandardSelected(null);
  }

  function detachStandardDisagree() {
    setStandardSelected(null);
  }

  const [detachEventAlertOpen, setDetachEventAlertOpen] = useState(false);
  function detachEventAgree(event) {
    api.getTaskAPI().updateTask({ ...taskSelected, event: null }).then(response => {
      let updatedTasks = updateTask(response.data);

      if (updatedTasks.length) {
        let updatedEvents = [...activePlan.events];

        for (let i = 0; i < updatedEvents.length; i++) {
          let ev = updatedEvents[i];
          if (ev.id === taskSelected.event) {
            ev.tasks = [...ev.tasks.filter(task => task !== taskSelected.id)];
          }
        }
        setActivePlan({ ...activePlan, events: updatedEvents, tasks: updatedTasks });
      }
    }).catch(error => {
      setAlertOpen(true);
    });

    setDetachEventAlertOpen(false);
  }

  function detachEventDisagree() {
    setDetachEventAlertOpen(false);
  }

  const [alertOpen, setAlertOpen] = useState(false);
  function handleClose(event) {
    setAlertOpen(false);
  }

  function addStandards(standardsSelected) {
    api.getTaskAPI().updateTask({ ...taskSelected, standards: [...taskSelected.standards, ...standardsSelected] }).then(response => {
      updateTask(response.data);
    }).catch(error => {
      setAlertOpen(true);
    })
  }

  function detachStandard(standard) {
    api.getTaskAPI().updateTask({ ...taskSelected, standards: [...taskSelected.standards.filter(stan => stan !== standard.id)] }).then(response => {
      updateTask(response.data);
    }).catch(error => {
      setAlertOpen(true);
    });
  }

  function attachEvent(event) {
    api.getTaskAPI().updateTask({ ...taskSelected, event: event.id }).then(response => {
      let updatedTasks = updateTask(response.data);

      if (updatedTasks.length) {
        try {
          let newEvent = event.id;
          let updatedEvents = [...activePlan.events];
          for (let i = 0; i < updatedEvents.length; i++) {
            let ev = updatedEvents[i];
            if (ev.id === newEvent) {
              ev.tasks = [...ev.tasks, taskSelected.id];
            }
          }

          setActivePlan({ ...activePlan, events: updatedEvents, tasks: updatedTasks });
        } catch (error) {
          console.log(error);
        }
      }
    }).catch(error => {
      setAlertOpen(true);
    });
  }

  const [filteredTasks, setFilteredTasks] = useState([]);

  useEffect(() => {
    if (tasks.length && !filteredTasks.length) {
      setFilteredTasks(tasks);
    }
  }, [tasks]);

  function navBar() {
    if (sideMenuOpen) {
      return (
        <Grid item container direction="column" style={{ borderRight: '1px solid #eaeaea', minWidth: onDesktop ? '360px' : '100%', maxWidth: onDesktop ? '360px' : '100%' }}>
          <Grid container item style={{ borderBottom: '1px solid #eaeaea', padding: '8px' }}>
            {!readOnly &&
              <Button disabled={loading} size="small" onClick={() => { history.push(`${match.url}/new`) }}>
                + New Task
              </Button>
            }
            <Search searchData={tasks} setSearchResults={setFilteredTasks} searchKeys={['name']} fullWidth style={{ padding: '8px' }} />
          </Grid>

          {!loading &&
            <Grid container item style={{ maxHeight: 'calc(100vh - 175px)', overflow: 'auto', padding: '4px' }}>
              {filteredTasks.map((task) => { return getTaskCard(task) })}
            </Grid>
          }

        </Grid>
      )
    }
  }

  let eventSelected = taskSelected ? activePlan.events.find(event => event.id === taskSelected.event) : null;

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      {!loading && navBar()}
      <Switch>
        <Route path={`${match.url}`} exact>
          {loading &&
            <Grid container alignItems="center" justify="center" style={{ height: '100%' }}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          }

        </Route>

        <Route path={`${match.url}/:task_pk/edit`}>
          <Task activePlan={activePlan} addTask={addTask} updateTask={updateTask} deleteTask={deleteTask} />
        </Route>

        <Route path={`${match.url}/new`}>
          <Task activePlan={activePlan} addTask={addTask} updateTask={updateTask} />
        </Route>

        <Route path={`${match.url}/:task_pk`}>

          <Grid item container justify="center" style={{ width: '100%', height: 'calc(100vh - 65px)', overflowY: 'auto', overflowX: 'auto' }}>
            {loading &&
              <Grid container item xs={12} alignItems="center" justify="center" style={{ height: '100%' }}>
                <CircularProgress />
              </Grid>
            }
            {!loading && taskSelected && <>
              <Grid container item xs={12} style={{ padding: '32px 16px', maxWidth: '1000px' }}>
                <Grid container style={{ width: '100%' }}>
                  <Grid item container xs={12} alignItems="center">
                    <Grid container item xs={12} style={{ padding: '16px 16px 0px 16px' }}>
                      <Grid container item xs={6} alignItems="center">
                        <Typography className={classes.header}>
                          Task Name
                        </Typography>
                      </Grid>
                      {!readOnly &&
                        <Grid container item xs={6} alignItems="center" justify="flex-end">
                          <Button variant="outlined" color="primary" size="small" onClick={() => { history.push(`${match.url}/${taskSelected.id}/edit`) }}>
                            Edit Task
                          </Button>
                        </Grid>
                      }
                    </Grid>
                    <Grid item xs={12} style={{ padding: '16px' }}>
                      <Paper elevation={0} style={{ padding: '16px', width: '100%' }}>
                        <Typography className={classes.title}>
                          {taskSelected.name}
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container xs={12} style={{ padding: '16px 16px 0px 16px' }}>
                  <Typography className={classes.header}>
                    Task Description
                  </Typography>
                </Grid>

                <Grid item container xs={12} style={{ padding: '16px' }}>
                  <Paper elevation={0} style={{ padding: '16px', minHeight: '142px', width: '100%' }}>
                    <Grid item xs={12}>
                      {taskSelected && taskSelected.description}
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item container xs={12} style={{ padding: '16px 16px 0px 16px' }}>
                  <Typography className={classes.header}>
                    Standard Operating Procedures (SOP)
                  </Typography>
                </Grid>

                <Grid item container xs={12} style={{ padding: '16px' }}>
                  <Paper elevation={0} style={{ minHeight: '284px', width: '100%' }}>
                    <Grid item xs={12}>
                      <SanitizeHtml value={taskSelected && taskSelected.procedures || ""} />
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item container xs={12} style={{ padding: '16px 16px 0px 16px' }}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography className={classes.header}>
                      Linked Event
                    </Typography>

                    {taskSelected && taskSelected.event &&
                      <IconButton style={{ margin: '0px 0px 0px 8px', padding: '0px' }} onClick={(event) => {
                        event.stopPropagation();
                        history.push(`/plans/manager/events/${taskSelected.event}/`);
                      }}>
                        <LaunchIcon />
                      </IconButton>
                    }
                  </Grid>
                  {!readOnly && eventSelected && eventSelected.event_type !== "LOG" &&
                    <Grid item container xs={6} justify="flex-end" alignItems="center">
                      {taskSelected.event &&
                        <Button size="small" onClick={() => setDetachEventAlertOpen(true)}>- Remove Link</Button>
                      }

                      {!taskSelected.event &&
                        <Button size="small" onClick={() => setAddEventDialogOpen(true)}>+ Add Link</Button>
                      }
                    </Grid>
                  }
                  <AddEventDialog open={addEventDialogOpen} setOpen={setAddEventDialogOpen} addEvent={attachEvent} />
                  <Prompt
                    open={detachEventAlertOpen}
                    agree={detachEventAgree}
                    disagree={detachEventDisagree}
                    title="Detach Event?"
                    description="Are you sure you'd like to detach this event? You can always add it back later."
                  />
                </Grid>

                {taskSelected.event != null &&
                  <Grid item container xs={12} style={{ padding: '16px' }}>
                    <EventWidget readOnly={readOnly} eventSelected={eventSelected} />
                  </Grid>
                }

                <Grid item container xs={12} style={{ padding: '16px 16px 0px 16px' }}>
                  <CustomRecordFields task={taskSelected} updateTask={updateTask} />
                </Grid>

                <Grid item container xs={12} style={{ padding: '16px 16px 0px 16px' }}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography className={classes.header}>
                      Linked Standards <span style={{ color: '#18bff6' }}>({taskSelected.standards.length})</span>
                    </Typography>
                  </Grid>
                  {!readOnly &&
                    <Grid item container xs={6} alignItems="center" justify="flex-end">
                      <Button size="small" onClick={() => setAddStandardsDialogOpen(true)}>+ Add Link</Button>
                    </Grid>
                  }
                </Grid>

                <Grid item container xs={12} style={{ padding: '16px' }}>
                  {taskSelected.read_standards.map((standard) => { return getStandardCard(standard) })}
                </Grid>

                <AddStandardsDialog
                  program={activePlan}
                  open={addStandardsDialogOpen}
                  setOpen={setAddStandardsDialogOpen}
                  addStandards={addStandards}
                />
              </Grid>
            </>
            }
          </Grid>
        </Route>
      </Switch>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="info" onClose={() => { setAlertOpen(false) }}>
          The task has been updated.
        </Alert>
      </Snackbar>
    </div>
  )
}
