import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {StylesContext} from "../../App";


export default function RecallProductionList(props) {
  function makeProductionTables() {
    if(props.productions.length === 0) {
      return (
        <Grid item container key={1} justify='center' alignItems='center' xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No Affected Productions</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
      )
    }

    return props.productions.map((productionObj, i) => {
      return(<> 
        <Grid item xs={12}>
          <Divider/>
        </Grid>

        <Grid item container key={i} justify='center' alignItems='flex-start' xs={6}>
          <Paper style={{width: '100%'}}>
              <Table style={{margin: '0px auto 8px'}}>
              <TableBody>
                <TableRow>
                  <TableCell> Formulation Name </TableCell>
                  <TableCell> {productionObj.formulation ? productionObj.formulation.name : 'No Matching Product Name'} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> ingredients </TableCell>
                  <TableCell> {productionObj.formulation?.ingredients?.map((ingredient, i) => {return (i > 0 ? ', ' + ingredient.ingredient_name : ingredient.ingredient_name)})} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> Description </TableCell>
                  <TableCell> {productionObj.formulation?.description} </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item container key={i} justify='center' alignItems='flex-start' xs={6}>
          <Paper style={{width: '100%'}}>
            <Table style={{margin: '0px auto 8px'}}>
              <TableBody>
                <TableRow>
                  <TableCell> Product Name </TableCell>
                  <TableCell> {productionObj.formulation ? productionObj.formulation.name : 'No Matching Product Name'} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> Inputs Lotcode </TableCell>
                  <TableCell> {productionObj?.ingredientInstances?.map((ingredient, i) => {return (i > 0 ? ', ' + 
                    ingredient?.lot_codes.map((lot, i) => {return (i > 0 ? ', ' + lot.code : lot.code)}) : 
                    ingredient?.lot_codes.map((lot, i) => {return (i > 0 ? ', ' + lot.code : lot.code)}))})} 
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>

        <Grid item container key={i} justify='center' alignItems='center' xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>M/O ID</TableCell>
                  <TableCell>Qty Produced</TableCell>
                  <TableCell>Production Date</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell> {productionObj.formulation ? productionObj.formulation.name : 'No Matching Product Name'} </TableCell>
                  <TableCell> {productionObj.status} </TableCell>
                  <TableCell> {productionObj.prod_lot_code} </TableCell>
                  <TableCell> {productionObj.final_unit_value ? Number(productionObj.final_unit_value).toFixed(2) + ' ' + props.units[productionObj.final_unit_type].abbreviation : 'Production not finished.'} </TableCell>
                  <TableCell> {productionObj.final_production_date ? productionObj.final_production_date :productionObj.production_date} </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </>)
    })
  }

  return(
    <Grid container spacing={3}>
      <Grid item container xs={12} alignItems="left" justify="flex-start">
        <Typography variant='h6'>
          Production List
        </Typography>
      </Grid>
      <Grid item container xs={12} alignItems="left" justify="flex-start">
        <Typography>
          A list of all production activities that involve the recalled lot codes.
        </Typography>
      </Grid>
      {makeProductionTables()}
    </Grid>
  )
}

RecallProductionList.propTypes = {
  productions: PropTypes.arrayOf(PropTypes.object),
  skus: PropTypes.objectOf(PropTypes.object),
  units: PropTypes.objectOf(PropTypes.object),
  disabled: PropTypes.bool,
  setShouldClose: PropTypes.func,
  closeModal: PropTypes.func,
}