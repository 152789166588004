import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Message from "../../Components/Message";
import RecallProductionList from "./RecallProductionList";
import RecallIngredientList from "./RecallIngredientList";
import RecallDistributionList from "./RecallDistributionList";
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Traceability from "../Traceability";
import TraceabilityUtils from "../TraceabilityUtils";
import { StylesContext } from "../../App";


export default function RecallForm(props) {
  const utils = new TraceabilityUtils();
  const classes = React.useContext(StylesContext);

  const [recall, setRecall] = useState(false);
  const [editingLotMode, setEditingLotMode] = useState(props.editingLotMode);
  const [lotCodeToAdd, setLotCodeToAdd] = useState('');
  const [message, setMessage] = useState(utils.emptyMessage());
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setRecall(props.recall);
  }, [props.recall])

  // console.log("RecallForm >> props.recall >> ", props.recall)

  // useEffect(() => {

  //   if (recall.external_lot_codes) {
  //     recall.external_lot_codes = recall.external_lot_codes.map((lotCodePk) => {
  //       if (typeof (lotCodePk) == 'object') {
  //         return lotCodePk;
  //       }
  //       return props.externalLotCodes.find(lotCode => (lotCode.pk == lotCodePk));
  //     })
  //   }
  // }, [recall])

  function handleAutoCompleteAddLotCode(newKey, newValue) {
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }

    setLotCodeToAdd(newValue);
  }

  function removeLotCodeObj(lotCodeIndex) {
    let newInternalLotCodes = [...recall.lot_codes];
    newInternalLotCodes.splice(lotCodeIndex, 1);
    setRecall({ ...recall, lot_codes: newInternalLotCodes });

    // if(type == 'internal'){
    //   let newInternalLotCodes = [...recall.internal_lot_codes];
    //   newInternalLotCodes.splice(lotCodeIndex, 1);
    //   setRecall({...recall, internal_lot_codes: newInternalLotCodes});
    // }
    // else if(type == 'external') {
    //   let newExternalLotCodes = [...recall.external_lot_codes];
    //   newExternalLotCodes.splice(lotCodeIndex, 1);
    //   setRecall({...recall, external_lot_codes: newExternalLotCodes});
    // }
  }

  function handleTextFieldChange(event) {
    if (props.setShouldClose) {
      props.setShouldClose(false);
    }
    const property = event.target.name;
    const value = event.target.value;

    setRecall({ ...recall, [property]: value });
  }

  function handleLotCodeSubmit() {
    if (lotCodeToAdd) {
      let existingLotCodes = recall.lot_codes ? recall.lot_codes : [];
      if(!existingLotCodes.includes(lotCodeToAdd)){
        setRecall({ ...recall, lot_codes: [...existingLotCodes, lotCodeToAdd] })
      }
      setLotCodeToAdd('')
    }
    // if(lotCodeToAdd && lotCodeToAdd.type == 'internal'){
    //   let existingLotCodes = recall.internal_lot_codes ? recall.internal_lot_codes : [];
    //   setRecall({...recall, internal_lot_codes: [...existingLotCodes, lotCodeToAdd]})
    //   setLotCodeToAdd('')
    // }
    // else if(lotCodeToAdd && lotCodeToAdd.type == 'external') {
    //   let existingLotCodes = recall.external_lot_codes ? recall.external_lot_codes : [];
    //   setRecall({...recall, external_lot_codes: [...existingLotCodes, lotCodeToAdd]})
    //   setLotCodeToAdd('')
    // }
  }

  //Return a closure.
  function returnFunction(i) {
    return function () {
      removeLotCodeObj(i);
    }
  }

  function makeLotCodePages(lotCodes) {

    let lotCodeObjects = lotCodes.map((lotCodeObj, i) => {
      if (!lotCodeObj) {
        return;
      }
      return (
        <Grid item container key={i} justify='center' alignItems='center' xs={12} style={{ width: '100%', height: '60px', marginBottom: '12px' }}>
          <Paper elevation={0} style={{ width: '60%', height: '60px', paddingLeft: '12px' }}>
            <Grid container spacing={3} justify='flex-start' alignItems='center' style={{ 'height': '60px' }}>
              <Grid item container xs={11} justify='flex-start' alignItems='center' style={{ position: 'relative', top: '5px' }} >
                <Typography>
                  {lotCodeObj}
                </Typography>
              </Grid>
              <Grid item xs={1} style={{ width: '100%', float: 'right', position: 'relative', top: '5px' }}>
                <IconButton style={{ float: 'right' }} onClick={returnFunction(i)}>
                  <RemoveCircleOutlineIcon style={{ float: 'right' }} />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )
    })
    return lotCodeObjects;
  }

  function validateForm() {
    // console.log("validateForm >> recall >> ",recall);
    if (!recall.recall_name) {
      setMessage({ open: true, message: 'Please specify a recall name.', status: 'error' });
      return false;
    }
    
    if (!recall.recall_type) {
      setMessage({ open: true, message: 'Please specify an recall type.', status: 'error' })
      return false;
    }

    if (!recall.lot_codes || recall.lot_codes.length == 0) {
      setMessage({ open: true, message: 'Please specify a recall lot codes.', status: 'error' })
      return false;
    }

    return true;
  }

  function validateFormLotCode() {
    if (!recall.recall_type) {
      setMessage({ open: true, message: 'Please specify an recall type.', status: 'error' })
      return false;
    }

    if (!recall.lot_codes || recall.lot_codes.length == 0) {
      setMessage({ open: true, message: 'Please specify a recall lot codes.', status: 'error' })
      return false;
    }

    return true;
  }

  function handleSubmit(finalsubmit) {
    // console.log("finalsubmit >> ",finalsubmit);
    setSubmitting(true);
    if (finalsubmit) {
      if (!validateForm()) {
        setSubmitting(false);
        return;
      }
    } else {
      if (!validateFormLotCode()) {
        setSubmitting(false);
        return;
      }
    }
    const api = new Traceability().getFRecallAPI();
    // let organization = JSON.parse(localStorage.getItem("organization"));
    // console.log("handleSubmit >> recall >> ",recall)
    let recallPacket = {};

    recallPacket.id = recall?.id;
    recallPacket.recall_name = recall.recall_name;
    recallPacket.recall_type = recall.recall_type;
    recallPacket.lot_codes = recall.lot_codes.map((lotCode) => lotCode)
    // console.log("recallPacket >> ",recallPacket);

    if(recallPacket.id) {
      api.updateFRecall(recallPacket).then(response => {
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }
        let newRecall = { ...response.data };
        setRecall(newRecall);

        setMessage({
          open: true,
          message: 'Saved Successfully',
          status: 'success',
        });
        if (props.onSave) {
          props.onSave(response, newRecall);
        }
        setEditingLotMode(false);
        setSubmitting(false);
      }).catch(error => {
        console.log("update >> error >> ",error);
        setSubmitting(false);
        setMessage({ open: true, message: 'Save Failed: ' + utils.formatError(error), status: 'error' });
      });
    }
    else {
      api.createFRecall(recallPacket).then(response => {
        if (props.setShouldClose) {
          props.setShouldClose(true);
        }
        let newRecall = { ...response.data };
        // console.log("newRecall >> res >> ",newRecall);
        setRecall(newRecall);

        setMessage({
          open: true,
          message: 'Saved Successfully',
          status: 'success',
        });
        if (props.onSave) {
          props.onSave(response, newRecall);
        }
        setEditingLotMode(false);
        setSubmitting(false);
      }).catch((error) => {
        console.log("insert >> error >> ",error);
        setSubmitting(false);
        setMessage({ open: true, message: 'Save Failed: ' + utils.formatError(error), status: 'error' });
      });
    }
    // props.closeModal()
  }

  function handleTypeChange(type) {
    setRecall({ ...recall, recall_type: type });
    setEditingLotMode(true);
  }

  // console.log("Recallfrom >> recall >> ", recall)
  return (
    <>
      {recall && !recall.recall_type && <>
        <Grid container spacing={3} className={classes.generalContainerGridHead} style={{ marginTop: '8px' }}>
          <Grid item xs={11}>
            <Typography variant="h6" noWrap className={classes.generalFormHeaderTypography}>
              Recall Type
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton style={{ float: 'right' }} onClick={props.closeModal}>
              <CancelIcon style={{ float: 'right' }} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container spacing={3} justify='center' alignItems='center' className={classes.generalContainerGridBody} style={{ marginTop: '80px' }}>
          <Grid item container justify='center' alignItems='center' xs={12} style={{ marginBottom: '40px' }}>
            <Typography variant='h2'>
              What kind of recall is this?
            </Typography>
          </Grid>

          <Grid item container justify='flex-end' alignItems='center' xs={6}>
            <Card onClick={() => { handleTypeChange('Mock') }} elevation={0} className={classes.recallTypeCard}>
              <CardContent>
                <Grid container spacing={3} justify='center' alignItems='center'>

                  <Grid item container justify='center' alignItems='center' xs={12}>
                    <Avatar aria-label="recall" style={{ backgroundColor: 'yellow' }}>
                      !
                    </Avatar>
                  </Grid>

                  <Grid item container justify='center' alignItems='center' xs={12}>
                    <Typography variant='h4'>
                      MOCK
                    </Typography>
                  </Grid>

                  <Grid item container justify='center' alignItems='center' xs={12}>
                    <Typography>
                      (A mock recall for audit purposes)
                    </Typography>
                  </Grid>

                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item container justify='flex-start' alignItems='center' xs={6}>
            <Card onClick={() => { handleTypeChange('Real') }} elevation={0} className={classes.recallTypeCard}>
              <CardContent>
                <Grid container spacing={3} justify='center' alignItems='center'>

                  <Grid item container justify='center' alignItems='center' xs={12}>
                    <Avatar aria-label="recall" style={{ backgroundColor: 'red' }}>
                      !
                    </Avatar>
                  </Grid>

                  <Grid item container justify='center' alignItems='center' xs={12}>
                    <Typography variant='h4'>
                      REAL
                    </Typography>
                  </Grid>

                  <Grid item container justify='center' alignItems='center' xs={12}>
                    <Typography>
                      (An actual recall)
                    </Typography>
                  </Grid>

                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>}

      {recall && recall.recall_type && editingLotMode && <>
        <Grid container spacing={3} className={classes.generalContainerGridHead} style={{ marginTop: '8px' }}>
          <Grid item xs={11}>
            <Typography variant="h6" noWrap className={classes.generalFormHeaderTypography}>
              Recalled Lots
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton style={{ float: 'right' }} onClick={props.closeModal}>
              <CancelIcon style={{ float: 'right' }} />
            </IconButton>
          </Grid>
        </Grid>


        <Grid container spacing={3} justify='center' alignItems='center' className={classes.generalContainerGridBody}>
          <Grid item container justify='center' alignItems='center' xs={12}>
            <Typography variant='h5'>
              Enter a least one lot code to recall
            </Typography>
          </Grid>

          <Grid item container justify='center' alignItems='center' xs={12} style={{ width: '100%' }}>
            <Paper elevation={0} style={{ width: '60%', backgroundColor: '#ECECEC', padding: '0px' }}>
              <Grid container spacing={3} justify='center' alignItems='center' >
                <Grid item xs={12} style={{ width: '100%' }}>
                  <Autocomplete
                    value={lotCodeToAdd}
                    id="lotCodeToAdd"
                    className={classes.generalFormTextField}
                    style={{ border: '0px solid rgba(0,0,0,0)', width: '100%' }}
                    options={props.lotCodes.map((lotCodeObj) => { return lotCodeObj })}
                    getOptionLabel={(option) => { return option ? option : '' }}
                    onChange={(emptyEvent, newValue) => { handleAutoCompleteAddLotCode('lotCodeToAdd', newValue) }}
                    renderInput={(params) => <TextField
                      {...params}
                      variant="outlined"
                      style={{ border: '0px solid rgba(0,0,0,0)', width: '100%' }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (<>
                          <InputAdornment position="end">
                            <IconButton style={{ float: 'right' }} onClick={() => { handleLotCodeSubmit() }}>
                              <AddCircleTwoToneIcon style={{ float: 'right' }} />
                            </IconButton>
                          </InputAdornment>
                          {params.InputProps.endAdornment}
                        </>),
                        style: { border: '0px solid rgba(0,0,0,0)' },
                      }}
                    />}
                    disabled={props.disabled}
                  />
                </Grid>
                {/*<Grid item xs={1} style={{ width: '100%', height: '0px', float: 'right', position: 'relative', bottom: '22px', right: '20px' }}>
                  <IconButton style={{ float: 'right' }} onClick={() => {handleLotCodeSubmit()}}>
                    <AddCircleTwoToneIcon style={{ float: 'right' }} />
                  </IconButton>
                    </Grid>*/}
              </Grid>
            </Paper>
          </Grid>

          <Grid item container justify='center' alignItems='center' xs={12}>
            <Divider />
          </Grid>

          {(!recall || recall.lot_codes.length === 0 
            // (recall.internal_lot_codes.length === 0 && recall.external_lot_codes.length === 0)
            ) && <>
            <Grid item container justify='center' alignItems='center' xs={12}>
              <Typography variant='h6'>
                No lot codes have been added to this recall
              </Typography>
            </Grid>
          </>}

          {/* {!!recall.external_lot_codes && recall.external_lot_codes.length > 0 && <>
            <Grid item container justify='center' alignItems='center' xs={12}>
              <Typography variant='h6'>
                External Lot Codes
              </Typography>
            </Grid>
            {makeLotCodePages(recall.external_lot_codes, 'external')}
          </>} */}

          {!!recall.lot_codes && recall.lot_codes.length > 0 && <>
            <Grid item container justify='center' alignItems='center' xs={12}>
              <Typography variant='h6'>
                Internal Lot Codes
              </Typography>
            </Grid>
            {makeLotCodePages(recall.lot_codes)}
          </>}

          <Grid item container justify='center' alignItems='center' xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <Paper elevation={0} className={classes.generalFormPaperStickyFooter}>
          <Grid container spacing={3} className={classes.generalContainerGridFoot}>
            <Grid item container xs={12} alignItems="center" justify="flex-end">
              <Button
                //variant="contained"
                color="primary"
                style={{ margin: "8px" }}
                onClick={() => { props.closeModal()}}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "8px" }}
                onClick={() => { handleSubmit(false)}}
                disabled={submitting}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </>}

      {recall && recall.recall_type && !editingLotMode && <>
        <Paper elevation={0} className={classes.generalFormPaperFieldHolder}>
          <Grid container alignItems="center" justify="center" spacing={3} className={classes.generalContainerGridHead} style={{ marginTop: '8px' }}>
            <Grid item xs={11}>
              <Typography variant="h6" noWrap>
                Recall Overview
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton style={{ float: 'right' }} onClick={props.closeModal}>
                <CancelIcon style={{ float: 'right' }} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container spacing={3} className={classes.generalContainerGridBody} style={{ marginTop: '8px' }}>

            <Grid item container xs={12} alignItems="flex-start" justify="flex-start">
              <Typography className={classes.generalFormTypographyHeader}>
                Recall Name
              </Typography>
              <TextField
                fullWidth
                className={classes.generalFormTextField}
                name="recall_name"
                variant="outlined"
                value={recall.recall_name}
                onChange={handleTextFieldChange}
                disabled={props.disabled}
              />
            </Grid>

            <Grid item container xs={9} alignItems="flex-start" justify="flex-start">
              <Typography className={classes.generalFormTypographyHeader}>
                Recalled Internal Lot Code(s)
              </Typography>
              <TextField
                fullWidth
                className={classes.generalFormTextField}
                name="lot_codes"
                variant="outlined"
                value={recall.lot_codes.map((lotCode, i) => { return i > 0 ? ' ' + lotCode : lotCode })}
                //onChange={handleTextFieldChange}
                disabled//={props.disabled}
              />
            </Grid>
            <Grid item container xs={3} alignItems="flex-start" justify="center">
              <Button
                variant="outlined"
                color="primary"
                style={{ marginLeft: "8px", position: 'relative', top: '45px' }}
                onClick={() => { setEditingLotMode(true) }}
              >
                ADD/REMOVE LOT CODES
              </Button>
            </Grid>

            <Grid item container xs={12} alignItems="flex-start" justify="flex-start">
              <Typography className={classes.generalFormTypographyHeader}>
                Potentially Impacted Lot Code(s)
              </Typography>
              <TextField
                fullWidth
                className={classes.generalFormTextField}
                name="potentially_impacted_lotcode"
                variant="outlined"
                value={recall.potentially_impacted_lotcode?.map((lotCode, i) => { return i > 0 ? ' ' + lotCode : lotCode })}
                //onChange={handleTextFieldChange}
                disabled//={props.disabled}
              />
            </Grid>


            <Grid item container xs={12} alignItems="center" justify="flex-end">
              <RecallProductionList
                productions={recall.potentially_impacted_production}
                // skus={props.skus}
                units={props.units}
              />
            </Grid>
            
            <Grid item container xs={12} alignItems="center" justify="flex-end">
              <RecallIngredientList
                ingredients={recall.potentially_impacted_ingredient}
                // skus={props.skus}
                units={props.units}
              />
            </Grid>

            <Grid item container xs={12} alignItems="center" justify="flex-end">
              <RecallDistributionList
                distributions={recall.potentially_impacted_shipping}
                // skus={props.skus}
                units={props.units}
              />
            </Grid>

          </Grid>
          {/* This Grid serves as the footer element. */}
          <Paper elevation={0} className={classes.generalFormPaperStickyFooter}>
            <Grid container spacing={3} className={classes.generalContainerGridFoot}>
              <Grid item container xs={12} alignItems="center" justify="flex-end">
              <Button
                  // variant="contained"
                  color="secondary"
                  style={{ marginLeft: "8px" }}
                  onClick={props.closeModal}
                >
                  cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "8px" }}
                  onClick={() => { handleSubmit(true)}}
                  disabled={submitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Paper>
      </>}
      <Message
        open={message.open}
        message={message.message}
        severity={message.status}
        vertical="bottom"
        horizontal="left"
        handleClose={() => { setMessage({ ...message, open: false }) }}
      />
    </>
  )
}

RecallForm.propTypes = {
  recall: PropTypes.object,
  editingLotMode: PropTypes.bool,
  lotCodes: PropTypes.arrayOf(PropTypes.object),
  skus: PropTypes.objectOf(PropTypes.object),
  units: PropTypes.objectOf(PropTypes.object),
  // externalLotCodes: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  setShouldClose: PropTypes.func,
  closeModal: PropTypes.func,
  onSave: PropTypes.func,
}