import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TraceabilityUtils from "../TraceabilityUtils";
import {StylesContext} from "../../App";


export default function RecallDistributionList(props) {
  const utils = new TraceabilityUtils();

  function makeFulfillmentLines(fulfillmentRecords,Shipment_Id,shipment_date) {
    return fulfillmentRecords.map((fulfillmentRecord, i) => {
      return(
        <TableRow key={i}>
          <TableCell>{Shipment_Id} </TableCell>
          <TableCell>{fulfillmentRecord?.product_detail?.name} </TableCell>
          <TableCell>{fulfillmentRecord?.lot_codes.map((lot, i) => {return (i > 0 ? ', ' + lot.code : lot.code)})} </TableCell>
          <TableCell>{fulfillmentRecord.total_unit_value ? Number(fulfillmentRecord?.total_unit_value).toFixed(2) + ' ' + props.units[fulfillmentRecord.total_unit_type].abbreviation : ''} </TableCell>
          <TableCell>{shipment_date} </TableCell>
        </TableRow>
      )
    })
  }

  function makeFulfillmentRecordTables(fulfillmentRecords, shippingRecord) {
    return(<>
      <Grid item container justify='center' alignItems='center' xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{'Shipment ' +  shippingRecord.id} </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell>{'Shipment Date ' + shippingRecord.shipment_date} </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>Shipment ID </TableCell>
                <TableCell>Product Name </TableCell>
                <TableCell>Lot Code </TableCell>
                <TableCell>QTY Shipped </TableCell>
                <TableCell>Date Shipped </TableCell>
              </TableRow>
              {makeFulfillmentLines(fulfillmentRecords,shippingRecord.id,shippingRecord.shipment_date)}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>)
  }

  function makeDistributionTables() {
    //This object comes in with a "count" key, will always be min size of one.
    if(Object.keys(props.distributions).length === 0) {
      return (
        <Grid item container key={1} justify='center' alignItems='center' xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No Affected Distributions</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
      )
    }

    return Object.keys(props.distributions).map((distributionKey, i) => {
      let shippingRecord = props.distributions[distributionKey];
      let affectedFulfillmentRecords = props.distributions[distributionKey].shipment_products;

      if(!shippingRecord) {
        return;
      }

      return(<> 
        <Grid item xs={12}>
          <Divider/>
        </Grid>

        <Grid item container key={i} justify='center' alignItems='flex-start' xs={6}>
          <Paper style={{width: '100%'}}>
            <Table style={{margin: '0px auto 8px'}}>
              <TableBody>
                <TableRow>
                  <TableCell> Customer Name </TableCell>
                  <TableCell> {shippingRecord?.customer_name} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> Customer Address </TableCell>
                  <TableCell> {shippingRecord?.customer_address} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> Customer ID </TableCell>
                  <TableCell> {shippingRecord?.customer} </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item container key={i} justify='center' alignItems='flex-start' xs={6}>
          <Paper style={{width: '100%'}}>
            <Table style={{margin: '0px auto 8px'}}>
              <TableBody>
                <TableRow>
                  <TableCell> Contact Name </TableCell>
                  <TableCell> {shippingRecord?.facility_name} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> Contact Phone </TableCell>
                  <TableCell> {shippingRecord?.facility_phone_number} </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> Contact Email </TableCell>
                  <TableCell> {shippingRecord?.facility_email} </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>

        { makeFulfillmentRecordTables(affectedFulfillmentRecords, shippingRecord) }
      </>)
    })
  }

  return(
    <Grid container spacing={3}>
      <Grid item container xs={12} alignItems="left" justify="flex-start">
        <Typography variant='h6'>
          Distribution List
        </Typography>
      </Grid>
      <Grid item container xs={12} alignItems="left" justify="flex-start">
        <Typography>
          A list of all shipments involving the recalled lot codes, broken down by supplier and shipment.
        </Typography>
      </Grid>
      {makeDistributionTables()}
    </Grid>
  )
}

RecallDistributionList.propTypes = {
  distributions: PropTypes.objectOf(PropTypes.object),
  skus: PropTypes.objectOf(PropTypes.object),
  units: PropTypes.objectOf(PropTypes.object),
  disabled: PropTypes.bool,
  setShouldClose: PropTypes.func,
  closeModal: PropTypes.func,
}