import React, { useEffect, useState } from "react";

import TabbableGroupedTable from "../../Components/GroupedTable/TabbableGroupTable";
import API from "../../Api/Api";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Traceability from "../Traceability";
import Button from "@material-ui/core/Button";
import TraceabilityUtils from "../TraceabilityUtils";
import Message from "../../Components/Message";
import FinishedInventoryAdjustmentForm from "./FinishedInventoryAdjustmentForm";
// import InventoryTransferForm from "./InventoryTransferForm";
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import Paper from "@material-ui/core/Paper";
import { StylesContext } from "../../App";
import { DialogActions, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, withStyles } from "@material-ui/core";

const EMPTY_MESSAGE = {
  open: false,
  message: '',
  status: 'info',
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "0px solid white",
        backgroundColor: "rgba(0, 0, 0, 0.029)",
      },
    },
  },
})(TextField);

export default function ItemInstanceTable(props) {
  const classes = React.useContext(StylesContext);
  const utils = new TraceabilityUtils();

  //item instances are naturally by sku currently.
  // const [itemInstances, setItemInstances] = useState(false);
  // const [itemInstancesByLotCode, setItemInstancesByLotCode] = useState(false);
  // const [itemInstancesBySku, setItemInstancesBySku] = useState(false);
  const [loading, setLoading] = useState(true);
  const [itemInstanceModalOpen, setItemInstanceModalOpen] = useState(false);
  const [itemInstanceSelected, setItemInstanceSelected] = useState(null);
  const [shouldClose, setShouldClose] = useState(true);
  const [itemAdjustmentModalOpen, setItemAdjustmentModalOpen] = useState(false);
  const [tabValue, grabTabValue] = useState('finishInventory');
  const [message, setMessage] = useState({ ...EMPTY_MESSAGE });
  const [user, setUser] = useState(null);
  const [formulations, setFormulations] = useState(null);
  // const [skus, setSkus] = useState(false);
  const [units, setUnits] = useState(false);
  // const [internalLotCodes, setInternalLotCodes] = useState(false);
  // const [inventoryLocations, setInventoryLocations] = useState(false);
  const [finishInventory, setFinishInventory] = useState(false);
  const [tabStatuses, setTabStatuses] = useState(false);

  // const byLocationColumns = [
  //   { title: "Inventory Location", field: "inventory_location" },
  //   { title: "SKU", field: "sku" },
  //   { title: "Qty In-Stock", field: "quantity" },
  //   { title: "Qty In Transit", field: "inTransit" },
  //   { title: "Qty Reserved", field: "inReserve" },
  //   { title: "Qty In Outgoing", field: "inOutgoing" },
  // ]

  // const bySkuColumns = [
  //   { title: "SKU", field: "sku" },
  //   { title: "Qty In-Stock", field: "quantity" },
  //   { title: "Qty In Transit", field: "inTransit" },
  //   { title: "Qty Reserved", field: "inReserve" },
  //   { title: "Qty In Outgoing", field: "inOutgoing" },
  //   //{ title: "Inventory Location", field: "inventory_location" },
  // ]

  const byFinishInventoryColumns = [
    { title: "Product", field: "product.name" },
    // { title: "Internal Lot Code", field: "internal_lot_code" },
    { title: "Total Produced", field: "total_unit_value" },
    {
      title: "Actions", field: "actions", render: rowData => (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => openModal(rowData)}
        >
          View
        </Button>
      )
    }
    // { title: "Manufacturing Date", field: "manufacturing_date" },
    // { title: "Shipped Amount", field: "shipped_amount" },
    // { title: "Remaining Amount", field: "remaining_amount" },
  ]

  useEffect(() => {
    // const api = new Traceability().getInstanceItemAPI();
    const authApi = new API().getAuthAPI();
    // const skuApi = new Traceability().getSkuApi();
    const unitApi = new Traceability().getUnitsAPI();
    const formulationApi = new Traceability().getFFormulationsAPI();
    // const inventoryLocationApi = new Traceability().getInventoryLocationAPI();
    const finishedInventoryApi = new Traceability().getFFinishedInventoryAPI();

    authApi.getAuthedProfile().then(e => {
      setUser(e.data);
    }).catch(e => {
      //console.log(e);
    })

    if (props.units) {
      setUnits(props.units);
    }
    else {
      utils.pkIndexObjectsFromApi(unitApi.listUnits.bind(unitApi), setUnits);
    }

    if (props.finishInventory) {
      setFinishInventory(props.finishInventory);
    }
    else {
      finishedInventoryApi.listFFinishedInventory().then(e => {
        setFinishInventory(e.data);
      }).catch(e => {
        //console.log(e);
      })
      // pkIndexObjectsFromApi(finishedInventoryApi.listFFinishedInventory.bind(finishedInventoryApi), setFinishInventory)
    }

    if (props.formulations) {
      setFormulations(props.formulations);
    }
    else {
      pkIndexObjectsFromApi(formulationApi.listFFormulations.bind(formulationApi), setFormulations)
      // formulationApi.listFFormulations().then(e => {
      //   setFormulations(e.data);
      // }).catch(e => {
      //   console.log(e);
      // })
    }

    // if (props.itemInstances) {
    //   let itemInstanceObjects = props.itemInstances;

    //   setItemInstances(itemInstanceObjects);
    // }
    // else {
    //   api.listFinishedInventorySkuUnits().then(e => {
    //     let itemInstanceObjects = e.data;
    //     setItemInstances(itemInstanceObjects);

    //     if (props.units) {
    //       setUnits(props.units);
    //     }
    //     else {
    //       utils.pkIndexObjectsFromApi(unitApi.listUnits.bind(unitApi), setUnits);
    //     }
    //   }).catch(e => {
    //     //console.log('BE Error: ' + e);
    //   })
    // }

    // if (props.itemInstancesLotCode) {
    //   let itemInstanceObjects = props.itemInstancesLotCode;

    //   setItemInstancesByLotCode(itemInstanceObjects);
    // }
    // else {
    //   api.listFinishedInventoryByLotCodeSkuUnits().then(e => {
    //     let itemInstancesLotCode = e.data;
    //     setItemInstancesByLotCode(itemInstancesLotCode);
    //   }).catch(e => {
    //     //console.log('BE Error: ' + e);
    //   })
    // }

    // if (props.skus) {
    //   let pkIndexedSkus = {};
    //   props.skus.forEach((skuObject) => {
    //     pkIndexedSkus[skuObject.pk] = { ...skuObject };
    //   });
    //   setSkus(pkIndexedSkus);
    // }
    // else {
    //   pkIndexObjectsFromApi(skuApi.listFinishedSkus.bind(skuApi), setSkus)
    // }

    // if (props.internalLotCodes) {
    //   let pkIndexedObjects = {};
    //   props.internalLotCodes.forEach((newObject) => {
    //     pkIndexedObjects[newObject.pk] = { ...newObject }
    //   });
    //   setInternalLotCodes(pkIndexedObjects);
    // }
    // else {
    //   pkIndexObjectsFromApi(internalLotCodeApi.listInternalLotCodesDisplay.bind(internalLotCodeApi), setInternalLotCodes);
    // }

    // if (props.inventoryLocations) {
    //   let pkIndexedObjects = {};
    //   props.inventoryLocations.forEach((newObject) => {
    //     pkIndexedObjects[newObject.pk] = { ...newObject }
    //   });
    //   setInventoryLocations(pkIndexedObjects);
    // }
    // else {
    //   pkIndexObjectsFromApi(inventoryLocationApi.listInventoryLocationsDisplay.bind(inventoryLocationApi), setInventoryLocations);
    // }

    setLoading(false);

  }, []);

  useEffect(() => {
    if (loading) {
      return;
    }

    setItemInstanceModalOpen(true);
  }, [itemInstanceSelected])

  // useEffect(() => {
  //   if (itemInstances && units) {
  //     groupItemInstancesBySku(itemInstances);
  //   }

  // }, [itemInstances, units])

  useEffect(() => {
    if (finishInventory) {
      setTabStatuses({
        counted: true,
        column: 'ignore',
        tabs: [
          { title: 'Inventory', value: 'finishInventory', count: finishInventory.length },
          // { title: 'By Lot Code', value: 'internal_lot_code', count: itemInstancesByLotCode.length },
          // { title: 'By Location', value: 'location', count: itemInstances.length },
        ]
      });
    }
  }, [finishInventory])

  function pkIndexObjectsFromApi(listFunction, setFunction, dataMassageFunction) {
    listFunction().then(e => {
      let pkIndexedObjects = {};

      e.data.forEach((newObject) => {
        pkIndexedObjects[newObject.id] = dataMassageFunction ? { ...dataMassageFunction(newObject) } : { ...newObject };
      });

      setFunction(pkIndexedObjects);
    }).catch(e => {
      //console.log(e);
    })
  }

  // function groupItemInstancesBySku(itemInstances) {
  //   let bySkuInstances = {};

  //   itemInstances.forEach((itemInstanceObj) => {
  //     if (bySkuInstances[itemInstanceObj.sku]) {
  //       let fromUnit = units[itemInstanceObj.unit_type];
  //       let toUnit = units[bySkuInstances[itemInstanceObj.sku].unit_type];

  //       bySkuInstances[itemInstanceObj.sku].unit_value_on_hand += Number(utils.getConvertedValue(itemInstanceObj.unit_value_on_hand, fromUnit, toUnit));
  //       bySkuInstances[itemInstanceObj.sku].unit_value_reserved += Number(utils.getConvertedValue(itemInstanceObj.unit_value_reserved, fromUnit, toUnit));
  //       bySkuInstances[itemInstanceObj.sku].unit_value_in_transit += Number(utils.getConvertedValue(itemInstanceObj.unit_value_in_transit, fromUnit, toUnit));
  //       bySkuInstances[itemInstanceObj.sku].unit_value_in_outgoing += Number(utils.getConvertedValue(itemInstanceObj.unit_value_in_outgoing, fromUnit, toUnit));
  //     }
  //     else {
  //       bySkuInstances[itemInstanceObj.sku] = {};
  //       bySkuInstances[itemInstanceObj.sku].unit_value_on_hand = Number(itemInstanceObj.unit_value_on_hand);
  //       bySkuInstances[itemInstanceObj.sku].unit_value_reserved = Number(itemInstanceObj.unit_value_reserved);
  //       bySkuInstances[itemInstanceObj.sku].unit_value_in_transit = Number(itemInstanceObj.unit_value_in_transit);
  //       bySkuInstances[itemInstanceObj.sku].unit_value_in_outgoing = Number(itemInstanceObj.unit_value_in_outgoing);
  //       bySkuInstances[itemInstanceObj.sku].unit_type = itemInstanceObj.unit_type;
  //     }
  //   })

  //   let bySkuArray = [];

  //   Object.keys(bySkuInstances).forEach((skuKey) => {
  //     let newIngObject = {
  //       sku: skuKey,
  //       unit_type: bySkuInstances[skuKey].unit_type,
  //       unit_value_on_hand: utils.formatNumber(bySkuInstances[skuKey].unit_value_on_hand),
  //       unit_value_reserved: utils.formatNumber(bySkuInstances[skuKey].unit_value_reserved),
  //       unit_value_in_transit: utils.formatNumber(bySkuInstances[skuKey].unit_value_in_transit),
  //       unit_value_in_outgoing: utils.formatNumber(bySkuInstances[skuKey].unit_value_in_outgoing),
  //     }

  //     bySkuArray.push(newIngObject)
  //   })

  //   setItemInstancesBySku(bySkuArray);
  // }

  function formatTableData(tableData) {
    let formattedTableData = [];
    tableData.forEach((dataPoint) => {
      let formattedPoint = {};
      //console.log('xsd dataPoint: ' + JSON.stringify(dataPoint))
      formattedPoint.product = dataPoint.product;
      formattedPoint.total_unit_value = dataPoint.total_unit_value + ' ' + units[dataPoint.base_unit_type]?.abbreviation;
      // formattedPoint.internal_lot_codes = dataPoint.internal_lot_codes.map((lotCodeData) => {
      //   return {
      //     "id": lotCodeData.id,
      //     "internal_lot_code": lotCodeData.internal_lot_code,
      //     "unit_value": lotCodeData.unit_value,
      //     "unit_type": lotCodeData.unit_type,
      //     "unit": lotCodeData.unit_value + ' ' + units[lotCodeData.unit_type]?.abbreviation,
      //     "manufacturing_date": lotCodeData.manufacturing_date,
      //   }
      // });
      formattedPoint.internal_lot_codes = dataPoint.internal_lot_codes
        .filter((lotCodeData) => lotCodeData.unit_value > 0) // Filter out lot codes with unit_value 0
        .map((lotCodeData) => {
          return {
            "id": lotCodeData.id,
            "internal_lot_code": lotCodeData.internal_lot_code,
            "unit_value": lotCodeData.unit_value,
            "unit_type": lotCodeData.unit_type,
            "unit": lotCodeData.unit_value + ' ' + units[lotCodeData.unit_type]?.abbreviation,
            "manufacturing_date": lotCodeData.manufacturing_date,
          }
        });

      // If no valid lot codes remain after filtering, set internal_lot_codes as an empty array
      if (formattedPoint.internal_lot_codes.length === 0) {
        formattedPoint.internal_lot_codes = [];
      }
      formattedPoint.id = dataPoint.product.id;
      formattedTableData.push(formattedPoint);
    })

    return formattedTableData;
  }

  function handleItemInstanceClosed() {
    if (shouldClose) {
      setItemInstanceModalOpen(false);
    }
  }

  /**
   * Handles keeping the list up to date with the back end, including updating all lines.
   * 
   * @param {JSON} response - a response object from the server
   */
  function onSave(response) {
    setItemInstanceModalOpen(false);
    const finishedInventoryApi = new Traceability().getFFinishedInventoryAPI();
    finishedInventoryApi.listFFinishedInventory().then(e => {
      setFinishInventory(e.data);
    }).catch(e => {
      // console.log(e);
    })

    // const api = new Traceability().getInstanceItemAPI();

    // if (props.itemInstances) {
    //   let itemInstanceObjects = props.itemInstances;

    //   setItemInstances(itemInstanceObjects);
    // }
    // else {
    //   api.listFinishedInventorySkuUnits().then(e => {
    //     let itemInstanceObjects = e.data;
    //     setItemInstances(itemInstanceObjects);
    //   }).catch(e => {
    //     //console.log('BE Error: ' + e);
    //   })
    // }

    // if (props.itemInstancesLotCode) {
    //   let itemInstanceObjects = props.itemInstancesLotCode;

    //   setItemInstancesByLotCode(itemInstanceObjects);
    // }
    // else {
    //   api.listFinishedInventoryByLotCodeSkuUnits().then(e => {
    //     let itemInstancesLotCode = e.data;
    //     setItemInstancesByLotCode(itemInstancesLotCode);
    //   }).catch(e => {
    //     //console.log('BE Error: ' + e);
    //   })
    // }

    setMessage({
      open: true,
      message: 'Saved Successfully',
      status: 'success',
    });
  }

  function openModal(itemInstance) {
    setItemInstanceSelected(itemInstance);
    setItemInstanceModalOpen(true);
  }

  function getColumns(tabVal) {
    if (tabVal == 'finishInventory') {
      return byFinishInventoryColumns;
    }
    // else if (tabVal == 'sku') {
    //   return bySkuColumns;
    // }
    // else if (tabVal == 'location') {
    //   return byLocationColumns;
    // }

    return byFinishInventoryColumns;
  }

  function getFormattedDataByTabValue(tabVal) {
    if (tabVal == 'finishInventory') {
      return formatTableData(finishInventory);
    }
    // else if (tabVal == 'finishInventory') {
    //   return formatTableData(itemInstancesBySku);
    // }
    // else if (tabVal == 'location') {
    //   return formatTableData(itemInstances);
    // }

    // return formatTableData(itemInstancesByLotCode);
    return formatTableData(finishInventory);
  }

  function openAdjustmentModal() {
    setItemAdjustmentModalOpen(true);
  }

  function handleItemAdjustmentClosed() {
    if (shouldClose) {
      setItemAdjustmentModalOpen(false);
    } else {
      alert("Save changes before closing.");
    }
  }
  console.log("finishInventory >> ", finishInventory)
  console.log("formulations >> ", formulations)
  return (
    <>
      {(!finishInventory || !tabStatuses) &&
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ marginTop: "250px" }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </Grid>
        </Grid>
      }

      {(finishInventory && tabStatuses) &&
        <Paper elevation={0} square className={classes.generalListPaperContainer} >
          <Grid container spacing={0} style={{ padding: '0px' }}>
            <Grid item xs={12}>
              <TabbableGroupedTable
                data={getFormattedDataByTabValue(tabValue)}
                formatData={formatTableData}
                columns={getColumns(tabValue)}
                draggable={!loading}
                title={'Finished Inventory'}
                grabTabValue={grabTabValue}
                tabStatuses={tabStatuses}
                // onRowClick={(event, rowData) => openModal(rowData)}
                actions={
                  [
                    //     {
                    //       icon: (props) => (
                    //         <Button size="small" color="primary" variant="outlined" className={classes.tabTableFreeAction}>
                    //           Inventory Transfer
                    //         </Button>
                    //       ),
                    //       tooltip: 'Make an Inventory Transfer',
                    //       isFreeAction: true,
                    //       onClick: (event) => openModal()
                    //     },
                    {
                      icon: (props) => (
                        <Button size="small" color="primary" variant="outlined" className={classes.tabTableFreeAction}>
                          Adjustment
                        </Button>
                      ),
                      tooltip: 'Make an Inventory Adjustment',
                      isFreeAction: true,
                      onClick: (event) => openAdjustmentModal()
                    },
                  ]
                }
              />
            </Grid>
          </Grid>

          <Dialog open={itemInstanceModalOpen} onClose={handleItemInstanceClosed} maxWidth="sm" fullWidth>
            <DialogTitle>All Product Lotcode</DialogTitle>
            <DialogContent style={{ overflow: 'hidden' }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Ingredient Name: {itemInstanceSelected?.product?.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Total Produced: {itemInstanceSelected?.total_unit_value}</Typography>
                </Grid>
              </Grid>

              {/* Example content */}
              <TableContainer component={Paper} elevation={1} style={{ padding: '12px', marginTop: '10px', overflow: 'hidden' }}>
                <Table style={{ margin: '0px 2% 8px' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Lot Code</TableCell>
                      <TableCell>Existing Quantity</TableCell>
                      <TableCell>Manufacturing Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!itemInstanceSelected && itemInstanceSelected?.internal_lot_codes && itemInstanceSelected.internal_lot_codes.map((lot, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography>{lot.internal_lot_code}</Typography>
                          {/* <CssTextField fullWidth variant="outlined" value={lot.internal_lot_code} disabled />
                       */}
                        </TableCell>
                        <TableCell>
                          <Typography>{lot.unit}</Typography>
                          {/* <CssTextField fullWidth variant="outlined" value={lot.unit} disabled /> */}
                        </TableCell>
                        <TableCell>
                          <Typography>{lot.manufacturing_date}</Typography>
                          {/* <CssTextField fullWidth variant="outlined" value={lot.manufacturing_date} disabled /> */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleItemInstanceClosed} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          {/* <Dialog fullScreen open={itemInstanceModalOpen} onClose={handleItemInstanceClosed} TransitionComponent={Transition}>
            <DialogContent className={classes.generalFormDialogueContainer}>
              <InventoryTransferForm
                //handleSubmit={() => {setShouldClose(true); //console.log('I am save, also finish fx')}}
                onSave={onSave}
                units={units}
                setShouldClose={setShouldClose}
                user={user}
                closeModal={() => { setItemInstanceModalOpen(false) }}
                activeTraceability={props.activeTraceability}
                skus={skus}
                internalLotCodes={internalLotCodes}
                inventoryLocations={inventoryLocations}
                itemInstances={itemInstances}
              />
            </DialogContent>
          </Dialog> */}

          <Dialog fullScreen open={itemAdjustmentModalOpen} onClose={handleItemAdjustmentClosed} TransitionComponent={Transition}>
            <DialogContent className={classes.generalFormDialogueContainer}>
              <FinishedInventoryAdjustmentForm
                onSave={onSave}
                units={units}
                setShouldClose={setShouldClose}
                user={user}
                closeModal={() => { setItemAdjustmentModalOpen(false) }}
                activeTraceability={props.activeTraceability}
                // skus={skus}
                formulations={formulations}
              // ingredients={ingredients}
              // internalLotCodes={internalLotCodes}
              // inventoryLocations={inventoryLocations}
              // itemInstances={itemInstances}
              />
            </DialogContent>
          </Dialog>

          <Message
            open={message.open}
            message={message.message}
            severity={message.status}
            vertical="bottom"
            horizontal="left"
            handleClose={() => { setMessage({ ...message, open: false }); setLoading(false) }}
          />
        </Paper>
      }
    </>
  )
}

ItemInstanceTable.propTypes = {
  // itemInstances: PropTypes.arrayOf(PropTypes.object),
  // skus: PropTypes.arrayOf(PropTypes.object),
  units: PropTypes.objectOf(PropTypes.object),
  // internalLotCodes: PropTypes.arrayOf(PropTypes.object),
  // inventoryLocations: PropTypes.arrayOf(PropTypes.object),
  activeTraceability: PropTypes.object.isRequired
}